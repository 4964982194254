import React from 'react';

import makeStyles from '@mui/styles/makeStyles';
import { Grid, Typography, Tooltip, IconButton, Link } from '@mui/material';
import { Menu, MenuItem } from '@mui/material';

import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';
import ReportOutlinedIcon from '@mui/icons-material/ReportOutlined';
import CachedOutlinedIcon from '@mui/icons-material/CachedOutlined';
import MoreHorizOutlinedIcon from '@mui/icons-material/MoreHorizOutlined';
import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined';

import ArtifactItemList from '../ArtifactItemList/ArtifactItemList';

const useStyles = makeStyles((theme) => ({
    div: {
        width: '100%'
    },
    "@keyframes logo-spin": {
        "from": {
          transform: "rotate(360deg)"
        },
        "to": {
          transform: "rotate(0deg)"
        }
    },
    iconSpinning: {
        animation: `$logo-spin 1500ms infinite linear`
    },
}));

export const ProjectSummaryView = ({project_id, project, summary, artifacts, 
                                    refreshDate, keywordLists,
                                    handleDelete, handleEdit, handleClone, handleRefresh}) => {
    const classes = useStyles();

    //console.log("ProjectSummaryView: "+project.projectName);
    //console.log("summary=");
    //console.log(summary);
    //console.log("artifacts=");
    //console.log(artifacts);

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
        event.stopPropagation();
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const getArtifactByExtension = (file_list, extension) => {
        let name = null;
        if(file_list) {
            for (let i = 0; i < file_list.length; i++) {
                let artifact = file_list[i];
                if(artifact.file_ext === extension) {
                    name = artifact.file_name;
                    break;
                }
            }
        }
        return name;    
    }

    const getArtifactMatchingName = (file_list, name) => {
        if(file_list) {
            for (let i = 0; i < file_list.length; i++) {
                let artifact = file_list[i];
                let file_name = artifact.file_name;
                if(file_name === name) {
                    return artifact;
                }
            }
        }
        return null;
    }

    const getSystemTextItemArtifact = (file_list) => {
        let ext = ".HPE";
        let hpe_name = getArtifactByExtension(file_list, ext);
        if(hpe_name) {
            let prefix = hpe_name.slice(0, -ext.length);
            let name = prefix + ".txt"
            return getArtifactMatchingName(file_list, name);
        }
        return null;
    }

    const getUnzippedHpeSize = (file_list) => {
        let unzipped_hpe = getSystemTextItemArtifact(file_list);
        //console.log("unzipped_hpe=");
        //console.log(unzipped_hpe);
        if(unzipped_hpe) {
            return unzipped_hpe.file_size;
        }
        return 0;
    }

    const isHpeTooBig = (artifact_list) => {
        if(artifact_list) {
            let ONE_MB = 1024*1024;
            let size = getUnzippedHpeSize(artifact_list.file_list);
            //console.log("size = "+size);

            if(size > ONE_MB) {
                return true;
            } 
        }
        return false;
    }

    const fileSizeString = (size) => {
        let KB = 1024;
        let MB = KB*KB;

        let s = "";
        if(size > MB) {
            let truncated = (size/MB);
            // For MBs, go out to 3 decimal points
            s = truncated.toFixed(3)+" MB";
        } else if(size > KB) {
            let truncated = (size/KB);
            s = truncated.toFixed(1)+" KB";
        } else {
            s = size+" bytes";
        }
        return s;
    }

    const getTooltipSizeText = (artifact_list) => {
        if(artifact_list) {
            let ONE_MB = 1024*1024;
            let size = getUnzippedHpeSize(artifact_list.file_list);
            //console.log("size = "+size);

            let size_str = fileSizeString(size);
            let text = "Uncompressed "+size_str;
            if(size > ONE_MB) {
                text = text + ". WARNING: Over 1MB."
            }
            return text;
        }
        return null;
    }

    const getTooltipGenerationDateText = (artifact_list) => {
        if(artifact_list) {
            let date = new Date(artifact_list.created);
            const months = ["January","February","March","April","May","June","July","August","September","October","November","December"];
            let date_str = date.getFullYear() + " " + months[date.getMonth()] + " " + date.getDate();
            
            const text = "Generated on " + date_str;
            return text;
        }
        return null;
    }

    const isProjectOutDated = (project) => {
        //console.log("project=");
        //console.log(project);

        //console.log("project.generated="+project.generated+"; refreshDate="+refreshDate);
        if(project.generated <= refreshDate)
            return true;

        //console.log("keywordLists=");
        //console.log(keywordLists);

        const exclusions = project.parameters.exclusions_id_list;
        //console.log("exclusions=");
        //console.log(exclusions);
        for(const id of exclusions) {
            for(const idx in keywordLists) {
                const kw = keywordLists[idx];
                if(id === kw.id) {
                    if(project.generated <= kw.modified) {
                        //console.log("keyword list '"+kw.name+"' has recent modifications");
                        return true;
                    }
                    continue;
                }
            }
        }

        const matches = project.parameters.matches_id_list;
        //console.log("matches=");
        //console.log(matches);
        for(const id of matches) {
            for(const idx in keywordLists) {
                const kw = keywordLists[idx];
                if(id === kw.id) {
                    if(project.generated <= kw.modified) {
                        //console.log("keyword list '"+kw.name+"' has recent modifications");
                        return true;
                    }
                    continue;
                }
            }
        }

        return false;
    }

    const getFormattedToolTip = (project, artifact_list, is_outdated) => {
        let tooltipAlertColor = "primary";
        if(is_outdated) {
            tooltipAlertColor = "orange";
        }

        //console.log("generated="+project.generated);
        //console.log("refreshDate="+refreshDate);
        //console.log("tooltipAlertColor="+tooltipAlertColor);
        //console.log("---------");
        
        return(
            <div>
                { getTooltipSizeText(artifact_list) }<br/>
                [<div style={{color: tooltipAlertColor, display: 'inline'}}>{ getTooltipGenerationDateText(artifact_list) }</div>]
            </div>
        );
    }

    const statusCode = (project.status != null) ? (project.status.code) : 0;

    const downloadAvailable = (summary != null) && (project.modified < summary.created);

    let downloadButtonColor = "primary";
    const outdated = isProjectOutDated(project);
    if(isHpeTooBig(artifacts)) {
        downloadButtonColor = "error"
    }

    // Extra logic in here to check 'downloadAvailable' to make sure we render correctly even if status is not populated
    let isProcessing = (statusCode === 0) || (project.generated < project.last_run); //!downloadAvailable;
    let isSuccess = (statusCode === 1) || downloadAvailable;
    let isError = (statusCode === -1);
    // HACK?
    //   Have a bug where a project has been successfully generated, but then errors out on re-run.
    //   The OR condition for calculating isProcessing seems like the origin of the bug but not sure what it is accounting for.
    if(isError) {
        isProcessing = false;
        isSuccess = false;
    }
    
    /*
    if(project && isError) {
        console.log("project=");
        console.log(project);

        const statusMsg = (project.status != null) ? (project.status.message) : "";
        console.log("["+project.projectName+"] project_id="+project_id+"; statusCode="+statusCode+"; statusMsg="+statusMsg+"; status=");
        console.log("isProcessing="+isProcessing+"; isSuccess="+isSuccess+"; isError="+isError+"; downloadAvailable="+downloadAvailable);
        console.log("------------------");
    }
    */

    /*
    if(summary && summary.created) {
        const statusMsg = (project.status != null) ? (project.status.message) : "";
        console.log("["+project.projectName+"] project_id="+project_id+"; statusCode="+statusCode+"; statusMsg="+statusMsg+"; status=");
        //console.log(project.status)
        console.log("isProcessing="+isProcessing+"; isSuccess="+isSuccess+"; isError="+isError+"; downloadAvailable="+downloadAvailable);
        console.log("project.modified="+project.modified+"; summary.created="+summary.created);
        console.log("------------------");
    }
    */

    return (
        <div className={classes.div}>
            <Grid container alignItems="center">
                <Grid item xs={8}>
                    <Typography variant="h6" color={!isError ? "primary": "secondary"}>{project.projectName}</Typography>
                </Grid>
                <Grid item xs={3}>
                    <Grid container spacing={2} direction="row" justifyContent="flex-end" alignItems="center" >
                        <Grid item xs={3}>
                            {/* This is the refresh button that is shown when artifacts are out of date */}
                            <IconButton
                                key={project_id+"-refresh-prompted"}
                                style={{ display: (outdated && !isProcessing) ? "block" : "none" }}
                                color="primary"
                                aria-label="refresh project"
                                component="span"
                                onFocus={(event) => event.stopPropagation()}
                                onClick={(event) => {
                                    event.stopPropagation();
                                    handleRefresh(project_id);
                                    handleMenuClose();
                                }}
                                size="large">
                                <RefreshOutlinedIcon fontSize="small" color={downloadButtonColor} />
                            </IconButton>
                        </Grid>
                        <Grid item xs={3}>
                            {/* These two share a single cell and only one is displayed */}
                            <IconButton
                                key={project_id+"-loading"}
                                disabled={true}
                                style={{ display: isProcessing ? "block" : "none" }}
                                aria-label="project settings"
                                component="span"
                                onClick={(event) => event.stopPropagation()}
                                onFocus={(event) => event.stopPropagation()}
                                size="large">
                                <CachedOutlinedIcon className={classes.iconSpinning} fontSize="small" color="primary" />
                            </IconButton>
                            <Tooltip title={getFormattedToolTip(project, artifacts, outdated)} aria-label="add" >
                                <IconButton
                                    key={project_id+"-download"}
                                    style={{ display: !isProcessing ? "block" : "none" }}
                                    disabled={!downloadAvailable}
                                    onClick={(event) => event.stopPropagation()}
                                    onFocus={(event) => event.stopPropagation()}
                                    component={ Link }
                                    href={(summary != null) ? summary.hpe_url : null}
                                    size="large">
                                    <CloudDownloadOutlinedIcon fontSize="small" color={downloadButtonColor}
                                                                style={{ display: isSuccess ? "block" : "none" }} /> 
                                    <ReportOutlinedIcon        fontSize="small" color={"secondary"}
                                                                style={{ display: isError ? "block" : "none" }} /> 
                                </IconButton>
                            </Tooltip>
                        </Grid>
                        <Grid item xs={3}>
                            <IconButton
                                key={project_id+"-more"}
                                style={{ display: true ? "block" : "none" }}
                                color="primary"
                                aria-label="project settings"
                                component="span"
                                onClick={handleMenuClick}
                                onFocus={(event) => event.stopPropagation()}
                                size="large">
                                <MoreHorizOutlinedIcon fontSize="small" color="primary"/>
                            </IconButton>

                            <Menu id="simple-menu" 
                                    anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)}
                                    onClose={handleMenuClose}
                            >
                                <MenuItem disableGutters={true} >
                                    <ArtifactItemList project_id={project_id} files={(artifacts != null) ? artifacts.file_list : null} />
                                </MenuItem>
                                <MenuItem disableGutters={true} >
                                    <IconButton
                                        key={project_id+"-refresh"}
                                        aria-label="refresh project"
                                        color="primary"
                                        component="span"
                                        onFocus={(event) => event.stopPropagation()}
                                        onClick={(event) => {
                                            event.stopPropagation();
                                            handleRefresh(project_id);
                                            handleMenuClose();
                                        }}
                                        size="large">
                                        <RefreshOutlinedIcon fontSize="small" color="primary" />
                                    </IconButton>
                                </MenuItem>
                                <MenuItem disableGutters={true} >
                                    <IconButton
                                        key={project_id+"-edit"}
                                        aria-label="edit project settings"
                                        color="primary"
                                        component="span"
                                        onFocus={(event) => event.stopPropagation()}
                                        onClick={(event) => {
                                            event.stopPropagation();
                                            handleEdit(project_id);
                                            handleMenuClose();
                                        }}
                                        size="large">
                                        <EditOutlinedIcon fontSize="small" color="primary" />
                                    </IconButton>
                                </MenuItem>
                                <MenuItem disableGutters={true} >
                                    <IconButton
                                        key={project_id+"-clone"}
                                        aria-label="clone project"
                                        color="primary"
                                        component="span"
                                        onFocus={(event) => event.stopPropagation()}
                                        onClick={(event) => {
                                            event.stopPropagation();
                                            handleClone(project_id);
                                            handleMenuClose();
                                        }}
                                        size="large">
                                        <FileCopyOutlinedIcon fontSize="small" color="primary" />
                                    </IconButton>
                                </MenuItem>
                                <MenuItem disableGutters={true} >
                                    <IconButton
                                        key={project_id+"-delete"}
                                        aria-label="delete project"
                                        color="primary"
                                        component="span"
                                        onFocus={(event) => event.stopPropagation()}
                                        onClick={(event) => {
                                            event.stopPropagation();
                                            handleDelete(project_id);
                                            handleMenuClose();
                                        }}
                                        size="large">
                                        <DeleteOutlinedIcon fontSize="small" color="primary" />
                                    </IconButton>
                                </MenuItem>
                            </Menu>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
}

export default ProjectSummaryView;