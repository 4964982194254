import React from 'react';

import Plot from 'react-plotly.js';

export const FrequencyDetails = ({frequencies}) => {

    return (
        <div>
            <Plot 
                data={[{
                    x: (frequencies != null) ? frequencies.x_vals_downscale : [],
                    y: (frequencies != null) ? frequencies.y_vals_names : [],
                    hovertext: (frequencies != null) ? frequencies.hovertext : "",
                    hoverinfo: "text",
                    hoverlabel: {
                        bgcolor: "white",
                        bordercolor: "blue"
                    },
                    type: 'bar',
                    xbins: { 
                        //start: (frequencies != null) ? frequencies.x_min : 0,
                        //end:   (frequencies != null) ? frequencies.x_max : 800000000, 
                        size:  10, 
                    },
                    mode: 'lines+markers',
                    marker: {
                        color: 'blue'
                    },
                    opacity: 0.55,
                }]}
                layout={{
                    //title: "RF Usage", 

                    // Margin configured to remove title area
                    margin: {
                      l: 50,
                      r: 50,
                      b: 50,
                      t: 50,
                      pad: 4
                    },

                    bargap: 0.2, 
                    bargroupgap: 0.2, 
                    xaxis: {
                        title: "Frequency (MHz)", 
                        range: [0,1000]
                    }, 
                    yaxis: {title: "Count"}
                }}
                useResizeHandler={true}
                style={{width: "100%", height: "150px"}}
            />
        </div>
    )
}

export default FrequencyDetails;