import React from 'react';

import { Container, Divider } from '@mui/material';


export const ErrorPage = () => {
    return (
        <Container maxWidth="md">
            <Divider/>
            <h1> Oops!  Page Not Found. </h1>
            <Divider/>
        </Container>
    );
}

export default ErrorPage;