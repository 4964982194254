import React from 'react';

import { deleteProjectPermanently } from '../../lib/apiEndpoints';

import ProjectListSelectableView from '../ProjectListSelectableView/ProjectListSelectableView';
import ConfirmationDialog from '../ConfirmationDialog/ConfirmationDialog';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
    displayArea: {
        display: "flex",
        flexFlow: "column",
        height: "100%",
    },
    fixedSize: {
        /* sized to content */
        flexGrow: 0,
        flexShrink: 1,
        flexBasis: "auto",
    },
    expandToRemainder: {
        /* fill remaining space */
        flexGrow: 1,
        flexShrink: 1,
        flexBasis: "auto",

        // critical to make sure scrolling works:  
        //  https://stackoverflow.com/questions/14962468/how-can-i-combine-flexbox-and-vertical-scroll-in-a-full-height-app
        height: "0px", 

        overflow: "auto",
    },
  }));

const ProjectList = ({projects, artifacts, summaries,
                        refreshDate, keywordLists,
                        handleSelectionChange, handleEditProject, handleCloneProject, handleRefreshProject,
                        notifyProjectDeleted}) => {
    const classes = useStyles();

    const [confirmationDialogOpen, setConfirmationDialogOpen] = React.useState(false);
    const [confirmationDialogPrompt, setConfirmationDialogPrompt] = React.useState("");
    const [confirmationDialogActionName, setConfirmationDialogActionName] = React.useState("");
    const [confirmationDialogAction, setConfirmationDialogAction] = React.useState(null);

    const confirmAction = (prompt, actionName, callback) => {
        // CRITICAL SYNTAX: 
        //  Pass an anonymous function that returns the callback
        //  https://medium.com/swlh/how-to-store-a-function-with-the-usestate-hook-in-react-8a88dd4eede1
        setConfirmationDialogAction(() => callback ); 

        setConfirmationDialogPrompt(prompt);
        setConfirmationDialogActionName(actionName);
        setConfirmationDialogOpen(true);
    }

    const handleDelete = async (project_id) => {
        console.log("Delete: "+project_id);

        const prompt = "Are you sure you want to delete the project '"+((project_id != null) ? projects[project_id].projectName : "")+"'?"
        confirmAction(prompt, "Delete", () => {
        console.log("Deleting project: "+project_id);
                        setConfirmationDialogOpen(false);
                        notifyProjectDeleted(project_id);
                        deleteProjectPermanently(project_id);
                    });
    }

    return (
        <div className={classes.displayArea} >
            <div className={classes.expandToRemainder} >
            <ProjectListSelectableView projects={projects} artifacts={artifacts} summaries={summaries}
                                        refreshDate={refreshDate} keywordLists={keywordLists}
                                        handleDelete={handleDelete} handleEdit={handleEditProject} 
                                        handleClone={handleCloneProject} handleRefresh={handleRefreshProject}
                                        handleSelectionChange={handleSelectionChange}/>

            </div>
            <ConfirmationDialog open={confirmationDialogOpen} 
                                    handleConfirm={confirmationDialogAction}
                                    handleCancel={() => { setConfirmationDialogOpen(false); }} 
                                    title={"Are you sure?"} 
                                    actionName={confirmationDialogActionName}
                                    message={confirmationDialogPrompt}
            />
        </div>
    );
}

export default ProjectList;
