import { CLEAR_SESSION, SET_SESSION } from '../constants/sessionActionTypes'
import cognitoUtils from '../lib/cognitoUtils'

export const clearSession = () => ({
  type: CLEAR_SESSION
})

// Initialise the Cognito sesson from a callback href
export function initSessionFromCallbackURI (callbackHref) {
    //console.log("SESSION:  initSessionFromCallbackURI:  href="+callbackHref);
    return function (dispatch) {
        //console.log("SESSION:  parseCognitoWebResponse:  href="+callbackHref);
        return cognitoUtils.parseCognitoWebResponse(callbackHref) // parse the callback URL
            .then(() => {
                //console.log("SESSION:  getCognitoSession");
                return cognitoUtils.getCognitoSession(); // get a new session
            })
            .then((session) => {
                //console.log("session=");
                //console.log(session);
                dispatch(setSession(session));
                //dispatch({ type: SET_SESSION, session })
            })
            .catch(error => {
                console.log("SESSION: Error parsing response")
                console.log(error);
            });
  }
}

export const setSession = (session) => ({
  type: SET_SESSION,
  session
})
