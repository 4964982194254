import React from 'react';
import { useState, useEffect } from 'react';

import { Typography, Grid, Button } from '@mui/material';

import { radiusInMiles } from '../../lib/locationHelpers';

const MapZoneEditorWindow = ({map, maps, 
                                visible,
                                id, item, original, 
                                handleSaveGlobal, handleSaveIndividual,
                                handleDelete, handleCancel}) => {
    //console.log("MapZoneEditorWindow");
    //console.log(item);
    //console.log(id);
    //console.log(original);
    //console.log(visible);

    const targetColor = "purple";
    const targetOpacity = 0.45;

    const shadowColor = "black";
    const shadowOpacity = 0.15;

    const [center, setCenter] = useState(null);
    const [radius, setRadius] = useState(null);
    const [target, setTarget] = useState(null);
    const [shadow, setShadow] = useState(null);

    const updateTargetDetails = (target) => {
        let c = target.getCenter();
        let r = target.getRadius();

        //console.log("updateTargetDetails:");
        //console.log(c);
        //console.log(r);

        setCenter({lat: c.lat(), lng: c.lng()});
        setRadius(r);
    }

    const cleanupMap = () => {
        //console.log("cleanupMap(): Clearing state variables");
        setCenter(null);
        setRadius(null);

        if(target) {
            target.setMap(null);
        }
        setTarget(null);

        if(shadow) {
            shadow.setMap(null);
        }
        setShadow(null);
    }

    const adjustBounds = (i, t, s) => {
        if(map && maps) {
            let bounds = new maps.LatLngBounds();

            if(i) {
                //console.log("fitting bounds for item");
                // Temporary circle for fitting bounds
                const tempCircle = new maps.Circle({
                    map: map,
                    strokeColor: "white", strokeOpacity: 0.8, strokeWeight: 2,
                    fillColor: "white", fillOpacity: 0.1,
                    center: i.center, radius: i.radius,
                });

                // Update bounds
                bounds.union(tempCircle.getBounds());
                map.fitBounds(bounds);

                // it's only temporary...
                tempCircle.setMap(null);
            }

            if(s) {
                //console.log("fitting bounds for shadow");
                // Update bounds
                bounds.union(s.getBounds());
                map.fitBounds(bounds);
            }

            if(t) {
                //console.log("fitting bounds for target");
                // Update bounds
                bounds.union(t.getBounds());
            }

            map.fitBounds(bounds);
        }
    }

    // Render when we have the data & map objects
    useEffect(() => {
        //console.log("MapZoneEditorWindow - useEffect()");
        if(item && map && maps) {
            //console.log("Got an item");
            //console.log("item:");
            //console.log(item);
            //console.log("original:");
            //console.log(original);
            //console.log("id="+id);

            //
            // By default, when the editor is active, the item is also still rendered.
            // For overrides that have already been processed, we want to see what the item
            //    looked like before it was overridden.  That's why we draw the shadow.
            // The target is what we are editing.  It is sized based on the most recent (if any)
            //    override dimensions or the item itself if there is nothing else.
            //

            //
            // Is this a location that has been processed?  or a new "draft"?
            //   We draw a shadow when it has already been proceseed.
            //   We set a specific target location if it's a draft.
            //
            let showShadow = false;
            let targetCenter = {
                lat: item.location.center.lat + 0.1, 
                lng: item.location.center.lng + 0.1
            };
            let targetRadius = item.location.radius;

            if(original) {
                // Item center being different from matchLocation means this has been applied.
                if(original.matchLocation) {
                    if(original.matchLocation.lat !== item.location.center.lat || 
                        original.matchLocation.lng !== item.location.center.lng ||
                        original.matchLocation.radius !== item.location.radius) {
                        showShadow = true;
                    }
                }

                // Use the override values for the target.
                targetCenter = {
                    lat: original.overrideLocation.lat + 0.1, 
                    lng: original.overrideLocation.lng + 0.1
                };
                targetRadius = original.overrideLocation.radius;
            }

            if(!center) {
                //console.log("setting center: ");
                //console.log(targetCenter);
                setCenter(targetCenter);
            }

            if(!radius) {
                //console.log("setting radius: "+targetRadius);
                setRadius(targetRadius);
            }

            // Create the "shadow" circle if this was generated from an override
            // Try to do this before the target so that target is on top in z order.
            let shadowCircle = null;
            if(!shadow && showShadow) {
                //console.log("Creating shadow");

                const shadowCenter = {
                    lat: original.matchLocation.lat, 
                    lng: original.matchLocation.lng
                };

                shadowCircle = new maps.Circle({
                    map: map,
                    strokeColor: shadowColor, strokeOpacity: 0.8, strokeWeight: 2,
                    fillColor: shadowColor, fillOpacity: shadowOpacity,
                    center: shadowCenter, radius: original.matchLocation.radius,
                });

                //console.log(targetCircle);
                setShadow(shadowCircle);
            } 

            // Create the target circle slightly offset from the item's center (by default)
            let targetCircle = null;
            if(!target) {
                //console.log("Creating target");
                targetCircle = new maps.Circle({
                    map: map,
                    strokeColor: targetColor, strokeOpacity: 0.8, strokeWeight: 2,
                    fillColor: targetColor, fillOpacity: targetOpacity,
                    center: targetCenter, radius: targetRadius,
                    draggable:true,
                    editable:true
                });

                maps.event.addListener(targetCircle, 'radius_changed', function() {
                    updateTargetDetails(targetCircle);
                });
                maps.event.addListener(targetCircle, 'center_changed', function() {
                    updateTargetDetails(targetCircle);
                });

                //console.log(targetCircle);
                setTarget(targetCircle);
            } 

            adjustBounds(item, targetCircle, shadowCircle);

        } else {
            cleanupMap();
        }

    }, [item, original, visible, map, maps]);

    //
    // NOTE:
    //   This control uses absolute positioning because I couldn't get WebControls to work with Google Maps.
    //   It requires the parent control to have "position: relative" set on it's CSS style.
    //
    return (
        <div style={{margin: "10px", position: "absolute", top: "10px", left: "10px"}} >

            <div  style={{ background: "white", borderColor: "black", 
                            padding: "10px",
                            borderStyle: "solid", borderRadius: "5px", borderWidth: "1px",
                            width: "300px",
                            display: visible ? "block" : "none" }} >

                <Typography variant="h6">Editor{id ? (": ["+id+"]") : ""}</Typography>

                <Typography>{(item != null) ? item.name : "<NONE>"}</Typography>

                <Typography variant="caption">Lat: {(center != null) ? center.lat.toFixed(2) : 0}</Typography><br/>
                <Typography variant="caption">Lng: {(center != null) ? center.lng.toFixed(2) : 0}</Typography><br/>
                <Typography variant="caption">Radius: {(radius != null) ? radiusInMiles(radius).toFixed(2) : 0} miles</Typography><br/>
                <br/>

                <Grid container justifyContent="space-between" spacing={3}>
                    <Grid item>
                        <Button variant="contained" color="secondary" size="small"
                                onClick={() => {
                                    cleanupMap();
                                    handleCancel();
                                }}>
                            Cancel
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button color="secondary" size="small" style={{ display: (original != null) ? "inline" : "none" }} 
                                onClick={() => {
                                    cleanupMap();
                                    handleDelete(item, original);
                                }}>
                            Remove
                        </Button>
                        &nbsp; &nbsp;
                        <Button variant="contained" color="primary" size="small"
                                onClick={() => {
                                    let centerResult = center;
                                    let radiusResult = radius;
                                    cleanupMap();
                                    if(id) {
                                        handleSaveIndividual(id, centerResult, radiusResult, original);
                                    } else {
                                        handleSaveGlobal(item, centerResult, radiusResult, original);
                                    }
                                }}>
                            Save
                        </Button>
                    </Grid>
                </Grid>
            </div>

        </div>
    );
}

export default MapZoneEditorWindow
