import React from 'react';
import { useState } from 'react';

import { Paper } from '@mui/material';
import { List, ListItem, ListItemText } from '@mui/material';

export const SelectionList = ({items, selections, multiple, handleSelectionChange}) => {
    const [lastIndex, setLastIndex] = useState(0);

    const handleSelection = (event, value) => {
        event.stopPropagation();

        //console.log("handleSelection()")
        //console.log(event);
        //console.log(value);

        const selectionIndex = items.indexOf(value);
        const itemSelected = (selections.indexOf(value) === -1);
        //console.log("Selection index: "+selectionIndex);
        //console.log("Last index: "+lastIndex);

        let rangeSelection = multiple && event.shiftKey && (lastIndex !== selectionIndex) && !(event.ctrlKey || event.metaKey);
        let multiSelect = multiple && (event.ctrlKey || event.metaKey) && !event.shiftKey;
        let singleSelect = !rangeSelection && !multiSelect;

        /*
        if(rangeSelection) {
            console.log("RANGE");
        }
        if(multiSelect) {
            console.log("MULTI")
        }
        if(singleSelect) {
            console.log("SINGLE");
        }
        */

        if(multiSelect) {
            const newSelections = [...selections];
            if (itemSelected) {
                // If it's not in there yet, then add it
                newSelections.push(value);
            } else {
                // Otherwize, remove it
                newSelections.splice(selections.indexOf(value), 1);
            }

            if(handleSelectionChange) { handleSelectionChange(newSelections); }
        } else if(rangeSelection) {
            let first = Math.min(selectionIndex, lastIndex);
            let last = Math.max(selectionIndex, lastIndex);
            // grab all items in range and make those the new selection
            const newSelections = [];
            for(let i = first; i <= last; i++) {
                let item = items[i];
                newSelections.push(item);
            }

            if(handleSelectionChange) { handleSelectionChange(newSelections); }
        } else if(singleSelect) {
            // We don't allow an empty list.
            if(handleSelectionChange) { handleSelectionChange([value]); }
        }

        // Update the last index selection.  
        // This will be the beginning of the next range selection.
        setLastIndex(selectionIndex);
      };

    return (
        <Paper style={{minHeight: "60vh", maxHeight: "60vh", overflow: 'auto'}}>
            <List>
                {items && items.map((value) => {
                    const labelId = `transfer-list-item-${value}-label`;
                    return (
                        <ListItem key={value} role="listitem" 
                                    button 
                                    selected={selections.indexOf(value) !== -1}
                                    onClick={(event) => handleSelection(event, value)}>
                            <ListItemText id={labelId}>{value}</ListItemText>
                        </ListItem>
                    );
                })}
            </List>
        </Paper>
    );
}

export default SelectionList;
