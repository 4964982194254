import React from 'react';

import makeStyles from '@mui/styles/makeStyles';

import { Container, Grid, Box } from '@mui/material';
import { FormControl, FormControlLabel, FormLabel, FormHelperText } from '@mui/material';
import { TextField , Switch, Button, Menu, MenuItem,  } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

import { THREEWAY_STATE_EXCLUDE, THREEWAY_STATE_INCLUDE, THREEWAY_STATE_ONLY } from '../../constants/toggleStates';
import ThreeWaySelection from '../ThreeWaySelection/ThreeWaySelection';

import { Formik, Form } from 'formik';
import * as Yup from "yup";
import MultiSelectComboBox from '../MultiSelectComboBox/MultiSelectComboBox';


const useStyles = makeStyles((theme) => ({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
      maxWidth: 300,
    },
    wrapper: {
      margin: "0px",
      position: 'relative',
    },
    buttonProgress: {
      color: "primary",
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    }
  }));

const TEMPLATE_STRING = "<TEMPLATE>";

export const ProjectConfigurationForm = ({project_name, project_id, project_params, 
                                            state_list, service_list, keywords_list, 
                                            handleSave, handleCancel}) => {
    const classes = useStyles();

    const [anchorElService, setAnchorElService] = React.useState(null);
    const [anchorElRegion, setAnchorElRegion] = React.useState(null);
    const [saveInProgress, setSaveInProgress] = React.useState(false);

    const isEditable = (handleCancel != null && handleSave != null);
    const buttonDisplay = (isEditable) ? "block" : "none";

    const serviceTagsMatchingText = (text) => {
        let tags = []
        for(let i = 0; i < service_list.length; i++) {
            let tag = service_list[i];
            if(tag.name.includes(text)) {
                tags.push(tag.id);
            }
        }
        return tags;
    }

    const mergeArraysWithoutDupes = (a1, a2) => {
        let a3 = a1.concat(a2);
        a3 = a3.filter((item,index)=>{
            return (a3.indexOf(item) === index)
        });
        return a3;
    }

    const mapValuesToObjects = (value_list, object_list, value_property_name) => {
        if(value_list && object_list) {
            let matching_objects = object_list.filter((obj) => value_list.includes(obj[value_property_name]));
            return matching_objects;
        }
        return [];
    }

    const mapObjectsToValues = (object_list, value_property_name) => {
        return object_list.map(obj => obj[value_property_name])
    }

    const handleServiceMenuClick = (event) => {
        setAnchorElService(event.currentTarget);
        event.stopPropagation();
    }

    const handleServiceMenuClose = () => {
        setAnchorElService(null);
    }

    const handleRegionMenuClick = (event) => {
        setAnchorElRegion(event.currentTarget);
        event.stopPropagation();
    }

    const handleRegionMenuClose = () => {
        setAnchorElRegion(null);
    }

    const initialValues = {
        projectName: project_name,

        stateCodes: mapValuesToObjects(project_params.state_code_list, state_list, "state_code"),
        serviceTags: mapValuesToObjects(project_params.service_id_list, service_list, "id"),
        exclusionLists: mapValuesToObjects(project_params.exclusions_id_list, keywords_list, "id"),
        matchesLists: mapValuesToObjects(project_params.matches_id_list, keywords_list, "id"),

        trunked: project_params.include_trunked,
        conventional: project_params.include_conventional,
        agencies: project_params.include_agencies,

        encrypted: project_params.include_encrypted,
        emptyLocations: project_params.include_empty_locations,

        addCounties: (project_params.hasOwnProperty("add_counties") ? project_params.add_counties : true),
        abbreviate: (project_params.hasOwnProperty("abbreviate") ? project_params.abbreviate : false)
    };

    const serviceTagGroups = [
        {
            "name": "Law",
            "tags": mapValuesToObjects(serviceTagsMatchingText("Law"), service_list, "id")
        },
        {
            "name": "Fire",
            "tags": mapValuesToObjects(serviceTagsMatchingText("Fire"), service_list, "id")
        },
        {
            "name": "EMS",
            "tags": mapValuesToObjects(serviceTagsMatchingText("EMS"), service_list, "id")
        },
        {
            "name": "Dispatch",
            "tags": mapValuesToObjects(serviceTagsMatchingText("Dispatch"), service_list, "id")
        },
        {
            "name": "<ALL>",
            "tags": mapValuesToObjects(serviceTagsMatchingText(""), service_list, "id")
        }
    ];

    const stateRegions = [
        {
            "name": "North East",
            "states": mapValuesToObjects(["ME","NH","VT","MA","CT","RI", "NY"], state_list, "state_code")
        },
        {
            "name": "New England",
            "states": mapValuesToObjects(["ME","NH","VT","MA","CT","RI"], state_list, "state_code")
        },
        {
            "name": "Mid Atlantic",
            "states": mapValuesToObjects(["NJ","DE","PA","MD","DC","VA"], state_list, "state_code")
        },
        {
            "name": "South East",
            "states": mapValuesToObjects(["NC","SC","GA","FL"], state_list, "state_code")
        },
        {
            "name": "Gulf Coast",
            "states": mapValuesToObjects(["TX","LA","MS","AL","FL"], state_list, "state_code")
        },
        {
            "name": "DC Metro",
            "states": mapValuesToObjects(["MD","DC","VA",], state_list, "state_code")
        },
        {
            "name": "Philadelphia Metro",
            "states": mapValuesToObjects(["PA","DE","NJ"], state_list, "state_code")
        },
        {
            "name": "NYC Metro",
            "states": mapValuesToObjects(["NY","NJ","CT"], state_list, "state_code")
        },
        {
            "name": "CONUS",
            "states": mapValuesToObjects([
                "ME","NH","VT","MA","CT","RI", "NY",
                "NJ","DE","PA","MD","DC","VA","WV",
                "MI","IL","IN","OH","KY","TN",
                "NC","SC","GA","FL",
                "TX","LA","MS","AL",
                "ND","SD","NE","MN","WI","IA","MO","KS","OK",
                "WA","OR","ID","MT","WY",
                "CA","NV","UT","AZ","CO","NM"
            ], state_list, "state_code")
        }
    ];

    const myValidation = Yup.object().shape({
        projectName: Yup.string()   
                        .nullable()
                        .matches(/^[a-zA-Z0-9_\-() ]+$/, "Please only use letters, numbers, spaces and the characters '_-.()'")
                        .required("Project name is required"),
        stateCodes: Yup.array().min(1, "Must specify at least one state to include"),
        serviceTags: Yup.array().min(1, "Must specify at least one service to include")
    });

    return (
        <Container maxWidth="xl" className={classes.root}>
            <Formik 
                initialValues={initialValues}
                validationSchema = {myValidation}
                onSubmit={(values) => {
                    if(handleSave) {
                        //console.log("Project Save");
                        //console.log("values=");
                        //console.log(values);
                        
                        setSaveInProgress(true);

                        // Map the tuples back to values that the API can accept
                        let mapped_values = {
                            projectName: values.projectName,
                            stateCodes: mapObjectsToValues(values.stateCodes, "state_code"),
                            serviceTags: mapObjectsToValues(values.serviceTags, "id"),
                            exclusionLists: mapObjectsToValues(values.exclusionLists, "id"),
                            matchesLists: mapObjectsToValues(values.matchesLists, "id"),
                            trunked: values.trunked,
                            conventional: values.conventional,
                            agencies: values.agencies,
                            encrypted: values.encrypted,
                            emptyLocations: values.emptyLocations,
                            addCounties: values.addCounties,
                            abbreviate: values.abbreviate,
                            status: null
                        };

                        //console.log("mapped_values=");
                        //console.log(mapped_values);

                        handleSave(project_id, mapped_values);
                    }
                }}
            >
                {({
                    values,
                    errors,
                    touched,
                    dirty,
                    isValid,
                    setFieldValue,
                    setFieldTouched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                }) => (

                    <Form>
                        <Box display={buttonDisplay}>
                            <TextField
                                variant="standard"
                                name="projectName"
                                label="Project Name"
                                required={isEditable}
                                autoFocus
                                disabled={!isEditable}
                                value={values.projectName}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={touched.projectName && Boolean(errors.projectName)}
                                helperText={errors.projectName}
                                fullWidth={true} />
                            <br/>
                        </Box>
                        <Grid container alignItems="center">
                            <Grid item xs={10} >
                                <FormControl
                                    variant="standard"
                                    style={{minWidth: "95%"}}
                                    required={isEditable}
                                    disabled={!isEditable}>
                                    <MultiSelectComboBox name="serviceTags" label="Service Tags"
                                                            isEditable={isEditable}
                                                            selection={values.serviceTags}
                                                            choices={service_list}
                                                            hasError={touched.serviceTags && Boolean(errors.serviceTags)}
                                                            handleChange={handleChange} handleBlur={handleBlur}
                                                            mapChoiceText={(item) => item.name}
                                                            setFieldValue={(v) => setFieldValue("serviceTags", v)}
                                    />
                                    <FormHelperText error={touched.serviceTags && Boolean(errors.serviceTags)}>{touched.serviceTags && errors.serviceTags}</FormHelperText>
                                </FormControl>
                            </Grid>
                            <Grid item xs>
                                <Button variant="contained" size="small"
                                        style={{ display: isEditable ? "block" : "none" }} 
                                        onClick={!isEditable ? null : handleServiceMenuClick}>
                                    Add Group
                                </Button>
                                <Menu id="simple-menu" keepMounted
                                        open={Boolean(anchorElService)}
                                        anchorEl={anchorElService}
                                        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                                        transformOrigin={{ vertical: "top", horizontal: "right" }}
                                        onClose={handleServiceMenuClose}
                                >
                                {
                                    serviceTagGroups.map((group, index) => 
                                        <MenuItem key={"tags_"+group["name"]}
                                                    onClick={() => {
                                                        let objs = group["tags"];
                                                        setFieldValue("serviceTags", mergeArraysWithoutDupes(values.serviceTags, objs));
                                                        handleServiceMenuClose();
                                                    }}
                                        >
                                            {group["name"]}
                                        </MenuItem>
                                    )
                                }
                                </Menu>
                            </Grid>
                        </Grid>
                        <Grid container alignItems="center">
                            <Grid item xs={10} >
                                <FormControl
                                    variant="standard"
                                    style={{minWidth: "95%"}}
                                    required={isEditable}
                                    disabled={!isEditable}>
                                    <MultiSelectComboBox name="stateCodes" label="States"
                                                            isEditable={isEditable}
                                                            selection={values.stateCodes}
                                                            choices={state_list}
                                                            hasError={touched.stateCodes && Boolean(errors.stateCodes)}
                                                            handleChange={handleChange} handleBlur={handleBlur}
                                                            mapChoiceText={(item) => item.state_name}
                                                            mapChipText={(item) => item.state_code}
                                                            setFieldValue={(v) => setFieldValue("stateCodes", v)}
                                    />
                                    <FormHelperText error={touched.stateCodes && Boolean(errors.stateCodes)}>{touched.stateCodes && errors.stateCodes}</FormHelperText>
                                </FormControl>
                            </Grid>
                            <Grid item xs>
                                <Button variant="contained" size="small"
                                        style={{ display: isEditable ? "block" : "none" }} 
                                        onClick={!isEditable ? null : handleRegionMenuClick}>
                                    Add Region
                                </Button>
                                <Menu id="simple-menu" keepMounted
                                        open={Boolean(anchorElRegion)}
                                        anchorEl={anchorElRegion}
                                        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                                        transformOrigin={{ vertical: "top", horizontal: "right" }}
                                        onClose={handleRegionMenuClose}
                                >
                                {
                                    stateRegions.map((region, index) => 
                                        <MenuItem key={"region_"+region["name"]}
                                                    onClick={() => {
                                                        let objs = region["states"];
                                                        setFieldValue("stateCodes", mergeArraysWithoutDupes(values.stateCodes, objs));
                                                        if(project_name.includes(TEMPLATE_STRING)) {
                                                            // Replace "<TEMPLATE>" with the region name
                                                            const new_name = project_name.replaceAll(TEMPLATE_STRING, region["name"]);
                                                            //console.log("new_name="+new_name);
                                                            setFieldValue("projectName", new_name);
                                                            // HACK:  Using setTimeout() to for sync state. isValid needs to synchronize.
                                                            // See here: https://github.com/jaredpalmer/formik/issues/2059
                                                            setTimeout(() => setFieldTouched("projectName", true));
                                                        }
                                                        handleRegionMenuClose();
                                                    }}
                                        >
                                            {region["name"]}
                                        </MenuItem>
                                    )
                                }
                                </Menu>
                            </Grid>
                        </Grid>
                        <br/>
                        <br/>
                        
                        <FormControl
                            variant="standard"
                            style={{minWidth: "100%"}}
                            className={classes.formControl}
                            disabled={!isEditable}>
                            <Grid container>
                                <Grid item xs>
                                    <FormLabel component="legend">System Types</FormLabel>
                                    <FormControlLabel
                                        control={<Switch name="trunked" color="primary"
                                                        checked={values.trunked}
                                                        onChange={handleChange}
                                                />
                                        }
                                        label="Trunked"
                                    /><br/>
                                    <FormControlLabel
                                        control={<Switch name="conventional" color="primary"
                                                        checked={values.conventional}
                                                        onChange={handleChange}
                                                />
                                        }
                                        label="Conventional"
                                    /><br/>
                                    <FormControlLabel
                                        control={<Switch name="agencies" color="primary"
                                                        checked={values.agencies}
                                                        onChange={handleChange}
                                                />
                                        }
                                        label="Agencies"
                                    />
                                </Grid>
                                <Grid item xs>
                                    <FormLabel component="legend">Encrypted Systems</FormLabel>
                                    <ThreeWaySelection name="encrypted"
                                                        kv1={["Exclude",THREEWAY_STATE_EXCLUDE]} 
                                                        kv2={["Include",THREEWAY_STATE_INCLUDE]} 
                                                        kv3={["Only",THREEWAY_STATE_ONLY]} 
                                                        value={values.encrypted}
                                                        onChange={(e,v) => {
                                                            setFieldValue(e.target.name, v); 
                                                        }}
                                    />
                                    <br/>
                                    <br/>
                                    <FormLabel component="legend">Empty Locations</FormLabel>
                                    <ThreeWaySelection name="emptyLocations"
                                                        kv1={["Exclude",THREEWAY_STATE_EXCLUDE]} 
                                                        kv2={["Include",THREEWAY_STATE_INCLUDE]} 
                                                        kv3={["Only",THREEWAY_STATE_ONLY]}
                                                        value={values.emptyLocations}
                                                        onChange={(e,v) => {
                                                            setFieldValue(e.target.name, v); 
                                                        }}
                                    />
                                </Grid>
                                <Grid item xs>
                                    <FormLabel component="legend">Naming</FormLabel>
                                    <FormControlLabel
                                        control={<Switch name="addCounties" color="primary"
                                                        checked={values.addCounties}
                                                        onChange={handleChange}
                                                />
                                        }
                                        label="Append County Names"
                                    /><br/>
                                    <FormControlLabel
                                        control={<Switch name="abbreviate" color="primary"
                                                        checked={values.abbreviate}
                                                        onChange={handleChange}
                                                />
                                        }
                                        label="Apply Abbreviations"
                                    />
                                </Grid>
                            </Grid>
                        </FormControl>
                        <Grid container alignItems="center">
                            <Grid item xs={10}>
                                <FormControl
                                    variant="standard"
                                    style={{minWidth: "95%"}}
                                    required={false}
                                    disabled={!isEditable}>
                                    <MultiSelectComboBox name="exclusionLists" label="Exclusion Terms"
                                                            isEditable={isEditable}
                                                            selection={values.exclusionLists}
                                                            choices={keywords_list}
                                                            hasError={touched.exclusionLists && Boolean(errors.exclusionLists)}
                                                            handleChange={handleChange} handleBlur={handleBlur}
                                                            mapChoiceText={(item) => item.name}
                                                            setFieldValue={(v) => setFieldValue("exclusionLists", v)}
                                    />
                                    <FormHelperText error={touched.exclusionLists && Boolean(errors.exclusionLists)}>{touched.exclusionLists && errors.exclusionLists}</FormHelperText>
                                </FormControl>
                            </Grid>
                            <Grid item xs >
                                <Button variant="contained" size="small" 
                                        style={{ display: isEditable ? "block" : "none" }} 
                                        onClick={!isEditable ? null : () => {
                                            setFieldValue("exclusionLists", keywords_list);
                                        }}>
                                    Add All
                                </Button>
                            </Grid>
                        </Grid>
                        <FormControl
                            variant="standard"
                            style={{minWidth: "100%"}}
                            required={false}
                            disabled={!isEditable}>
                            <MultiSelectComboBox name="matchesLists" label="Required Match Terms"
                                                    isEditable={isEditable}
                                                    selection={values.matchesLists}
                                                    choices={keywords_list}
                                                    hasError={touched.exclusionLists && Boolean(errors.exclusionLists)}
                                                    handleChange={handleChange} handleBlur={handleBlur}
                                                    mapChoiceText={(item) => item.name}
                                                    setFieldValue={(v) => setFieldValue("matchesLists", v)}
                            />
                            <FormHelperText error={touched.matchesLists && Boolean(errors.matchesLists)}>{touched.matchesLists && errors.matchesLists}</FormHelperText>
                        </FormControl>
                        <Box display={buttonDisplay}>
                            <br/>
                            <Grid container justifyContent="flex-end" spacing={3}>
                                <Grid item>
                                    <Button variant="contained" color="secondary" onClick={handleCancel}>
                                        Cancel
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <div className={classes.wrapper} >
                                        <Button variant="contained" type="submit" color="primary" 
                                                onSubmit={handleSubmit}
                                                disabled={!dirty || !isValid || saveInProgress}>
                                            Save & Generate
                                        </Button>
                                        {saveInProgress && <CircularProgress size={24} className={classes.buttonProgress} />}
                                    </div>
                                </Grid>
                            </Grid>
                        </Box>
                    </Form>
                )}
            </Formik>
        </Container>
    );
}

export default ProjectConfigurationForm;