import React from 'react';
import { useState, useEffect, useRef } from 'react';

import { Formik, Form } from 'formik';
import * as Yup from "yup";

import makeStyles from '@mui/styles/makeStyles';
import { Container, Grid, TextField, IconButton } from '@mui/material';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

import { getUserKeywordLists, createUserKeywordList, updateUserKeywordList, deleteUserKeywordList } from '../../lib/apiEndpoints';

import { SelectionList } from '..';


const useStyles = makeStyles((theme) => ({

}));

export const KeywordsEditor = () => {
    const classes = useStyles();

    const dataLoaded = useRef(false);
    const [keywordsRawData, setKeywordsRawData] = useState([]);

    const [keywordsState, setKeywordsState] = useState({
        nameList: [],
        nameToTermsDict: {},
        nameToIdDict: {}
    });

    const [textNewListName, setTextNewListName] = useState("");
    const [textNewTermName, setTextNewTermName] = useState("");

    const [activeList, setActiveList] = useState("");
    const [activeTerms, setActiveTerms] = useState([]);

    const [activeListSelections, setActiveListSelections] = useState([]);
    const [activeTermsSelections, setActiveTermsSelections] = useState([]);

    // Load all data when control first loads
    useEffect(() => {
        //console.log("useEffect() - keywordsRawData");
        async function fetchKeywordLists() {           
            await getUserKeywordLists()
                    .then( data => {
                        //console.log("user Keyword lists");
                        //console.log(data);
                        //console.log(data.keyword_lists_dict)
                        setKeywordsRawData(data.keyword_lists_dict);
                        dataLoaded.current = true;
                    })
                    .catch(error => {
                        console.log("Uh oh")
                        console.log(error);
                        dataLoaded.current = true;
                    });
        }

        // Load the data
        if(!dataLoaded.current) {
            fetchKeywordLists();
        } else {
            // Then post process it
            const newState = postProcessKeywords(keywordsRawData, dataLoaded.current);
            if(newState) {
                setKeywordsState(newState);
            }
        }

    }, [keywordsRawData]);

    const listNameValidation = Yup.object().shape({
        listName: Yup.string()   
                    //.nullable()
                    .matches(/^[a-zA-Z0-9_\-() ]+$/, "Please only use letters, numbers, spaces and the characters '_-()'")
                    .required("List name is required")
    });
    const lisItemValidation = Yup.object().shape({
        itemName: Yup.string()   
                    //.nullable()
                    .matches(/^[-a-zA-Z0-9_.,'/() ]+$/, "Please only use letters, numbers, spaces and the characters '_-.,'/()'")
                    .required("Item text is required")
    });

    const postProcessKeywords = (data, isDataLoaded) => {
        //console.log("postProcessKeywords");
        //console.log(data);

        if(isDataLoaded) {
            //console.log("processing keywords dict");
            let nameList = [];
            let nameToTermsDict = {};
            let nameToIdDict = {};

            let idList = Object.keys(data);
            for(let i = 0; i < idList.length; i++) {
                let id = idList[i];
                //console.log(id);
                let listName = data[id].listName;
                let terms = data[id].keywordTerms;

                // Put them in ascending order
                terms.sort((a, b) => (a > b) ? 1 : -1)

                //console.log(listName);

                nameList.push(listName);
                nameToTermsDict[listName] = terms;
                nameToIdDict[listName] = id;
            }

            // Put them in ascending order by name of the list
            nameList.sort((a, b) => (a > b) ? 1 : -1)

            const newState = {
                nameList: nameList,
                nameToTermsDict: nameToTermsDict,
                nameToIdDict: nameToIdDict
            };
            return newState;
        }

        return null;
    }

    async function createNewList(name) {    
        //console.log("createUserKeywordList")

        let values = {
            "listName": name, 
            "keywordTerms": []
        };

        // Clear out the old text now that it has been processed
        setTextNewListName("");

        await createUserKeywordList(values)
                .then(data => {
                    //console.log(data);
                    let entry = {
                        keywordTerms: [],
                        listName: name,
                        modified: Date.now()
                    }
                    let dict = Object.assign({}, keywordsRawData);
                    dict[data.listId] = entry;
                    setKeywordsRawData(dict);
                })
                .then(data => {
                    // And set the selection to be this new list
                    setActiveListSelections([name])
                    activateListByName(name);
                })
                .catch(error => {
                    console.log("Error creating user keyword list")
                    console.log(error);
                });
    }

    async function deleteList(listId) {
        //console.log("deleteList: "+listId);
        await deleteUserKeywordList(listId)
                .then(data => {
                    //console.log("listId="+listId);
                    //console.log(keywordsRawData);
                    let dict = Object.assign({}, keywordsRawData);
                    let entry = dict[listId];
                    if(entry) {
                        //console.log("entry=");
                        //console.log(entry);
                        delete dict[listId];

                        setKeywordsRawData(dict);
                    } else {
                        console.log("unable to find list name from listId");
                    }
                })
                .catch(error => {
                    console.log("Error deleting user keyword list")
                    console.log(error);
                });
    }

    async function updateList(listId, listName, values) {  
        //console.log("updateList: "+listId);  
        await updateUserKeywordList(listId, listName, values)
                .then(data => {
                    //console.log("listId="+listId);
                    //console.log("listName="+listName);
                    console.log(keywordsRawData);
                    let dict = Object.assign({}, keywordsRawData);
                    let entry = dict[listId];
                    if(entry) {
                        //console.log("entry=");
                        //console.log(entry);
                        entry.listName = listName;
                        entry.keywordTerms = values;
                        dict[listId] = entry;

                        setKeywordsRawData(dict);
                    }
                })
                .catch(error => {
                    console.log("Error updating user keyword list")
                    console.log(error);
                });
    }

   function handleEnterKey(e, eventHandler) {
        if(e.keyCode === 13){
            //console.log("<ENTER>");
            eventHandler();
            return true;
        }
        return false;
    }

    function activateListByName(name) {
        setActiveList(name);
        if(keywordsState.nameToTermsDict.hasOwnProperty(name)) {
            setActiveTerms(keywordsState.nameToTermsDict[name]);
        } else {
            setActiveTerms([]);
        }
    }

    const removeItemsFromArray = (items, a) => {
        //console.log("removeItemsFromArray");
        let newArray = [...a];
        for(let i = 0; i < items.length; i++) {
            let item = items[i];
            let idx = newArray.findIndex(element => element === item);
            newArray.splice(idx, 1);
        }
        //console.log(items);
        //console.log(newArray);
        return newArray;
    };

    const handleAddList = () => {
        //console.log("handleAddList");
        createNewList(textNewListName);
    }

    const handleDeleteList = () => {
        //console.log("handleDeleteList");

        for(let i = 0; i < activeListSelections.length; i++) {
            let name = activeListSelections[i];
            let listId = keywordsState.nameToIdDict[name];
            deleteList(listId);
        }

        // Active list just got deleted.  So no selection in either list.
        setActiveList("");
        setActiveTerms([]);
    };

    const handleAddTermToList = () => {
        //console.log("handleAddTermToList");
        if(activeListSelections.length > 0) {
            let listName = activeListSelections[0];
            let listId = keywordsState.nameToIdDict[listName];

            let newActiveTerms = [...activeTerms];
            newActiveTerms.push(textNewTermName);
            setActiveTerms(newActiveTerms);

            updateList(listId, listName, newActiveTerms);

            // Clear out the old text now that it has been processed
            setTextNewTermName("");
        } else {
            console.log("no active selections");
        }
    }

    const handleDeleteTerms = () => {
        //console.log("handleDeleteTerms");
        if(activeListSelections.length > 0) {
            let listName = activeListSelections[0];
            let listId = keywordsState.nameToIdDict[listName];

            let newActiveTerms = removeItemsFromArray(activeTermsSelections, activeTerms);
            setActiveTerms(newActiveTerms);

            updateList(listId, listName, newActiveTerms);
        } else {
            console.log("no active selections");
        }
    };

    return (
        <Container maxWidth="xl" className={classes.root}>
            <Grid container direction="row" alignItems="flex-start" justifyContent="space-evenly" spacing={3}>
                <Grid item xs={4}>
                    <Formik
                        initialValues={{ listName: textNewListName }}
                        validationSchema={listNameValidation}
                        onSubmit={() => {}}
                    >
                        {({ errors, handleChange }) => (
                            <Form>
                                <TextField
                                    variant="standard"
                                    fullWidth={true}
                                    label="New list name"
                                    name="listName"
                                    value={textNewListName}
                                    onChange={e => {
                                        handleChange(e);
                                        setTextNewListName(e.target.value);
                                    }}
                                    onKeyUp={e => {
                                        if(!errors.listName) handleEnterKey(e, handleAddList);
                                    }}
                                    error={errors.listName}
                                    helperText={errors.listName} />
                            </Form>
                        )}
                    </Formik>
                </Grid>
                <Grid item xs={2}>
                    <IconButton color="primary" onClick={handleAddList} size="large">
                        <AddCircleOutlineIcon/>
                    </IconButton>
                </Grid>
                <Grid item xs={4}>
                    <Formik
                        initialValues={{ itemName: textNewTermName }}
                        validationSchema={lisItemValidation}
                        onSubmit={() => {}}
                    >
                        {({ errors, handleChange }) => (
                            <Form>
                                <TextField
                                    variant="standard"
                                    fullWidth={true}
                                    label="Text to match"
                                    name="itemName"
                                    value={textNewTermName}
                                    onChange={e => {
                                        handleChange(e);
                                        setTextNewTermName(e.target.value);
                                    }}
                                    onKeyUp={e => {
                                        if(!errors.itemName) handleEnterKey(e, handleAddTermToList);
                                    }}
                                    error={errors.itemName}
                                    helperText={errors.itemName} />
                            </Form>
                        )}
                    </Formik>
                </Grid>
                <Grid item xs={2}> 
                    <IconButton color="primary" onClick={handleAddTermToList} size="large">
                        <AddCircleOutlineIcon/>
                    </IconButton>
                </Grid>
                <Grid item xs={4}>
                    <SelectionList items={keywordsState.nameList} selections={activeListSelections}
                                    handleSelectionChange={(selections) => {
                                        //console.log("keywordListNames - selection changed:");
                                        //console.log(selections);
                                        if(selections && selections.length > 0) {
                                            setActiveListSelections(selections);
                                            activateListByName(selections[0]);
                                        } else {
                                            if(activeListSelections.length > 0) {
                                                setActiveListSelections([]);
                                            }
                                            if(activeList.length > 0) {
                                                setActiveList("");
                                            }
                                            if(activeTerms.length > 0) {
                                                setActiveTerms([]);
                                            }
                                        }
                                    }}
                    />
                </Grid>
                <Grid item xs={2}>
                    <IconButton color="primary" onClick={handleDeleteList} size="large">
                        <HighlightOffIcon/>
                    </IconButton>
                </Grid>
                <Grid item xs={4}>
                    <SelectionList items={activeTerms} selections={activeTermsSelections}
                                    multiple={true}
                                    handleSelectionChange={(selections) => {
                                        //console.log("activeTerms - selection changed:");
                                        //console.log(selections);
                                        setActiveTermsSelections(selections);
                                    }}
                    />                                
                </Grid>
                <Grid item xs={2}>
                    <IconButton color="primary" onClick={handleDeleteTerms} size="large">
                        <HighlightOffIcon/>
                    </IconButton>
                </Grid>
            </Grid>
        </Container>
    );
}

export default KeywordsEditor;
