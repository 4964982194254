import React from 'react';
import { useState, useEffect } from 'react';

import { Box, Container, Divider } from '@mui/material';
import { Typography } from '@mui/material';
import { LinearProgress } from '@mui/material';

import { getCacheStatus } from '../lib/apiEndpoints';

const STATUS_TABLE_SIZE = "table_size";
const STATUS_IS_ACTIVE = "is_active";

const STATUS_START = "start";
const STATUS_DETAILS = "details";
const STATUS_PERCENT = "percent";
const STATUS_REMAINING = "remaining";
const STATUS_EST_END = "estimated_end";
const STATUS_COMPLETED = "completed";

const KB = 1024;
const MB = KB*KB;


function LinearProgressWithLabel(props) {
    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ width: '100%', mr: 1 }}>
                <LinearProgress variant="determinate" {...props} />
            </Box>
            <Box sx={{ minWidth: 35 }}>
                <Typography variant="body2" color="primary">{`${Math.round(props.value,)}%`}</Typography>
            </Box>
        </Box>
    );
}

export const CacheStatus = () => {

    const [cacheStatus, setCacheStatus] = useState(null);

    async function fetchCacheStatus() {           
        await getCacheStatus()
                .then( data => {
                    //console.log("cache status");
                    //console.log(data);
                    if(data && data.status_results) {
                        setCacheStatus(data.status_results);
                    }
                })
                .catch(error => {
                    console.log("Uh oh")
                    console.log(error);
                    //const newState = { ...staticData };
                    //newState.usStates.error = true;
                    //newState.usStates.loaded = true;
                    setCacheStatus(null);
                });
    }

    // Load all data when page first loads
    useEffect(() => {
        fetchCacheStatus();
    }, []);

    const getDateTimeText = (timestamp) => {
        if(timestamp) {
            const months = ["Jan","Feb","Mar","Apr","May","June","July","Aug","Sept","Oct","Nov","Dec"];
            const days = ["Sun","Mon","Tues","Weds","Thurs","Fri","Sat"];
            let date = new Date(timestamp);
            let date_str = days[date.getDay()] + ", " + months[date.getMonth()] + " " + date.getDate();
            let time_str = date.getHours().toString().padStart(2, '0') + ":" + date.getMinutes().toString().padStart(2, '0')
            return date_str+", "+time_str;
        }
        return null;
    }

    const getRemainingTimeText = (remaining) => {
        if(remaining && remaining > 0) {
            let date = new Date(remaining);

            let days = (date.getUTCDate() - 1); // Returns day of month from 1-31
            let hours = date.getUTCHours();
            let mins = date.getUTCMinutes();

            let str = "";
            if(days > 0) {
                str += days.toString();
                str += (days > 1) ? " days " : " day ";
            }
            if(hours > 0) {
                str += hours.toString().padStart(2, '0') + " hrs ";
            }
            if(mins > 0) {
                str += mins.toString().padStart(2, '0') + " mins";
            }
            
            return str;
        }
        return null;
    }

    if(!cacheStatus) {
        return (
            <Container maxWidth="md">
                <Divider/>
                <h1> Cache Status [Loading...] </h1>
                <Divider/>
            </Container>
        );
    } else {
        return (
            <Container maxWidth="md">
                <Divider/>
                <br/>
                <Typography variant="h4" color="primary">Cache Tables</Typography>
                <br/>
                <div  style={{ "overflowX": "auto", "overflowY": "auto"}}>
                    {
                        Object.keys(cacheStatus).sort().reverse().map((table_name, index) => {
                            const item = cacheStatus[table_name];
                            //console.log("table_name="+table_name);
                            //console.log(item);
                            
                            const is_active = item[STATUS_IS_ACTIVE];
                            const table_size = item[STATUS_TABLE_SIZE];
                            let size_str = "";
                            if(table_size > MB) {
                                size_str = (table_size/MB).toFixed(2) + " MB";
                            } else if(table_size > KB) {
                                size_str = (table_size/KB).toFixed(2) + " KB";
                            } else {
                                size_str = table_size + " bytes";
                            }

                            const status = item["status"] ? JSON.parse(item["status"]) : null;
                            if(status) {
                                //console.log("status=");
                                //console.log(status);
                            }

                            const percent = status ? status[STATUS_PERCENT] : null;   
                            const progress = (percent? percent * 100 : 100);

                            // For some reason, the details dictionary is treated as a string instead of a dictionary
                            // Additionally, the state abbreviations are wrapped with single quotes rather than double.
                            // We need to swap those (' -> ") so that the JSON parse will work
                            const details = status ? JSON.parse(status[STATUS_DETAILS].replaceAll("'", '"')) : null;
                            let details_str = null;
                            if(details) {
                                //console.log("details=");
                                //console.log(details);
                                const states = Object.keys(details);
                                const total_num = states.length;
                                let finished_states = [];

                                let active_state = "";
                                let active_progress = 0.0;
                                for(let i = 0; i < states.length; i++) {
                                    const s = states[i];
                                    const p = details[s];
                                    if(p !== 0) { // non-zero progress
                                        if(p === 1.0) {
                                            finished_states.push(s);
                                        } else { // partial progress is currently active
                                            active_state = s;
                                            active_progress = p;
                                        }
                                    }
                                }

                                details_str = "[" + (finished_states.length+1) + "/" + total_num + "] ";
                                details_str += active_state + ": " + (active_progress*100).toFixed(2) + "%";
                            }

                            const started = status ? status[STATUS_START] : null;
                            const start_time_str = getDateTimeText(started);

                            const remaining = status ? status[STATUS_REMAINING] : null;
                            const remaining_time_str = getRemainingTimeText(remaining);

                            const estimated_end = status ? status[STATUS_EST_END] : null;
                            const est_end_str = getDateTimeText(estimated_end);

                            const completed = status ? status[STATUS_COMPLETED] : null;
                            const completed_str = getDateTimeText(completed);

                            return (
                                <div key={table_name}>
                                    <div style={{display:"flex"}}>
                                        <Typography variant="h6" color="primary" noWrap>
                                            {table_name}
                                        </Typography>
                                        <Typography variant="h6" color="secondary" noWrap 
                                                    style={{ display: is_active ? "block" : "none", marginLeft:10 }} >
                                            [active]
                                        </Typography>
                                    </div>
                                    <LinearProgressWithLabel value={progress} />
                                    <div style={{display:"flex"}}>
                                        <Typography variant="subtitle1" color="primary" noWrap>
                                            Table Size: {size_str}
                                        </Typography>
                                        <Typography variant="subtitle1" color="primary" noWrap
                                                    style={{ display: started ? "block" : "none", marginLeft:10 }}>
                                            | &nbsp; Started: {start_time_str}
                                        </Typography>
                                        <Typography variant="subtitle1" color="primary" noWrap
                                                    style={{ display: completed ? "block" : "none", marginLeft:10 }}>
                                            | &nbsp; Completed: {completed_str}
                                        </Typography>
                                    </div>
                                    <div style={{display: (!completed || completed === 0.0) ? "flex" : "none"}}>
                                        <Typography variant="subtitle1" color="primary" noWrap
                                                    style={{ display: details_str ? "block" : "none", marginLeft:10 }}>
                                            {details_str}
                                        </Typography>
                                        <Typography variant="subtitle1" color="primary" noWrap
                                                    style={{ display: remaining_time_str ? "block" : "none", marginLeft:10 }}>
                                            | &nbsp; Remaining: {remaining_time_str}
                                        </Typography>
                                        <Typography variant="subtitle1" color="primary" noWrap
                                                    style={{ display: estimated_end ? "block" : "none", marginLeft:10 }}>
                                            | &nbsp; Est. Completion: {est_end_str}
                                        </Typography>
                                    </div>
                                    <br/>
                                    <br/>
                                </div>
                            );
                        })
                    }
                </div>
                <Divider/>
            </Container>
        );
    }
}

export default CacheStatus;