export const radiusInMiles = (r) => {
    return r/1609;
}

export const createLocationObject = (lat, lng, radius) => {
    if(lat == null || lng == null || radius == null) {
        console.log("*** ERROR:  createLocationObject() - a parameter is null");
        console.log("*** ERROR:  createLocationObject() - lat="+lat+", lng="+lng+", radius="+radius);
        return null;
    }

    let center = {lat: parseFloat(lat), lng: parseFloat(lng)};
    let location = {center: center, radius: parseFloat(radius)};
    return location;
}

export const locationObjectFromCenterRadius = (center, radius) => {
    let location = createLocationObject(center.lat, center.lng, radius);
    return location;
}

export const locationObjectFromArray = (parts) => {
    if(parts.length === 3) {
        let lat = parts[0];
        let lng = parts[1];
        let radius = parts[2];
        let location = createLocationObject(lat, lng, radius);
        return location;
    }
    return null;
}

export const locationObjectFromDict = (dict) => {
    if(dict) {
        if("lat" in dict && "lng" in dict && "radius" in dict) {
            let lat = dict["lat"];
            let lng = dict["lng"];
            let radius = dict["radius"];
            let location = createLocationObject(lat, lng, radius);
            return location
        }
    }
    return null;
}

export const dictKeyFromLocation = (location) => {
    if(!location) {
        console.log("*** ERROR:  dictKeyFromLocation() - location is null");
    }

    let key = location.center.lat + "_" + location.center.lng + "_" + location.radius;
    return key;
}

export const locationFromDictKey = (key) => {
    let parts = key.split("_");
    return locationObjectFromArray(parts);
}
