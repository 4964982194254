import React from 'react';

import { Redirect } from 'react-router-dom'

import { connect } from 'react-redux'
import { initSessionFromCallbackURI } from '../actions/session'

import { Container, Divider } from '@mui/material';

function mapStateToProps (state) {
  return { session: state.session }
}
function mapDispatchToProps (dispatch) {
  return {
    initSessionFromCallbackURI: href => dispatch(initSessionFromCallbackURI(href))
  }
}

export const AuthenticationCallback = (props) => {
    
    const getParams = (search) => {
        let searchParams = new URLSearchParams(search);
        let params = {}
        for (const [key, value] of searchParams.entries()) {
            params[key] = value;
        }
        return params;
    }

    if(props.session.isLoggedIn === false) {
        //console.log("props=");
        //console.log(props);
    
        //console.log("looking for session:");
        //console.log(props.session);
    
        let params = getParams(props.location.search);
        //console.log("params=");
        //console.log(params);
        
        if(params.code != null) {
            //console.log("initializing session");
            let href = window.location.href;
            props.initSessionFromCallbackURI(href);
        }

        return (
            <Container maxWidth="md">
                <Divider/>
                <h1> Authentication Callback. </h1>
                <Divider/>
            </Container>
        );

    } else {
        //console.log("LOGGED IN");
        //console.log("session=");
        //console.log(props.session);
        return <Redirect to="/dashboard" />
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AuthenticationCallback)
