import React from 'react'
import TextField from '@mui/material/TextField';
import { Checkbox } from '@mui/material';

import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export const MultiSelectComboBox = ({name, label, key,
                                    isEditable,
                                    selection, choices, hasError, 
                                    handleChange, handleBlur, 
                                    mapChoiceText, mapChipText, 
                                    setFieldValue}) => {

    const filterOptions = createFilterOptions({
        matchFrom: 'any',
        ignoreCase: true,
        stringify: (option) => {
            let s = "";
            for(let prop in option) {
                if(typeof option[prop] === "string") {
                    s += option[prop];
                }
            }
            return s;
        },
      });

    return (
        <Autocomplete
            multiple
            disableCloseOnSelect={false}
            blurOnSelect={false}
            size="small"

            fullWidth={true}
            id={key}

            options={choices}
            value={selection}

            filterOptions={filterOptions}

            // Text for the selection 'chips'
            getOptionLabel={(option) => {
                let txt = (mapChipText ? mapChipText(option) : mapChoiceText(option));
                return txt;
            }}
            
            onChange={(e,values,reason) => {
                //console.log("MultiSelectComboBox:onChange() - "+reason);
                //console.log("values=");
                //console.log(values);
                //console.log("e=");
                //console.log(e);
                //console.log("----");
                if(setFieldValue) {
                    setFieldValue(values);
                }
                if(handleChange) {
                    handleChange(e);
                }
            }}

            renderOption={(props, option, { selected }) => (
                <li {...props}>
                    <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                    />
                    { mapChoiceText(option) }
                </li>
            )}

            renderInput={(params) => (
                <TextField {...params} 
                            variant="standard" 
                            label={label} placeholder={label} 
                            error={hasError} disabled={!isEditable} 
                            onBlur={(e) => {
                                //console.log("onBlur");
                                //console.log(e);
                                if(handleBlur) {
                                    handleBlur(e);
                                }
                            }}
                />
            )}
        />
    );
}

export default MultiSelectComboBox;
