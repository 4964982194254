import React from 'react'
import makeStyles from '@mui/styles/makeStyles';
import logoImg from "./assets/ico_loader.svg";

const useStyles = makeStyles((theme) => ({
    "@keyframes logo-spin": {
        "from": {
          transform: "rotate(0deg)"
        },
        "to": {
          transform: "rotate(360deg)"
        }
    },
    wrapper: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        width: '100px',
        height: '100px',
        transform: 'translate(-50%, -50%)'
    },
    icon: {
        width: '100px',
        height: '100px',
        position: 'absolute',
        left: 0,
        top: 0,
        background: "url("+logoImg+") no-repeat 0 0",
        backgroundSize: '248px 248px',
        animation: `$logo-spin 500ms infinite linear`
    },
}));

export const Loader = () => {
    const classes = useStyles();
    return (
        <div className={classes.wrapper}>
            <div className={classes.icon} />
        </div>
    )
}

export default Loader;
