import React from 'react';
import { useState } from 'react';

import FrequencyDetails from '../FrequencyDetails/FrequencyDetails';

import makeStyles from '@mui/styles/makeStyles';

import { IconButton } from '@mui/material';

import EqualizerOutlinedIcon from '@mui/icons-material/EqualizerOutlined';
import ExpandLessOutlinedIcon from '@mui/icons-material/ExpandLessOutlined';

const useStyles = makeStyles((theme) => ({
    displayArea: {
        width: "100%",
        padding: "5px"
    },
    drawerVisible: {
        margin: '0px',
        borderStyle: "none",
        width: "100%",
    },
    drawerClosed: {
        margin: '0px',
        borderStyle: "none",
        width: "100%",
        // Using absolute positioning to hide but still get 100% width working correctly
        // If we instead use display: none, then width is not calculated correctly when the drawer starts closed/hidden.
        // Solution described here:  https://css-tricks.com/comparing-various-ways-to-hide-things-in-css/
        position: 'absolute',
        top: '-9999px',
        left: '-9999px'
    },
    frequencies: {
        marginTop: '2px',
        borderStyle: "none",
        width: "100%",
        overflow: 'hidden'
    },
    panelIconOpen: {
        margin: '-5px',
        borderStyle: "none",
        width: "100%",
    },
    panelIconClose: {
        marginLeft: '0px',
        marginRight: '0px',
        marginTop: '-10px',
        marginBottom: '-10px',
        borderStyle: "none",
        width: "100%",
        background: 'rgba(255, 255, 255, 1)',
    },
  }));

export const FrequencyDetailsDrawer = ({frequencies}) => {
    const classes = useStyles();

    const [frequencyDrawerOpen, setFrequencyDrawerOpen] = useState(false);

    return (
        <div className={classes.displayArea}>
            <div className={frequencyDrawerOpen ? classes.dradrawerVisiblewer : classes.drawerClosed} >
                <div className={classes.frequencies} >
                    <FrequencyDetails frequencies={frequencies} />
                </div>
                <div className={classes.panelIconClose} >
                    <IconButton
                        color="primary"
                        aria-label="hide frequencies"
                        component="span"
                        edge='end'
                        onClick={() => {
                            setFrequencyDrawerOpen(!frequencyDrawerOpen);
                        }}
                        size="large">
                        <ExpandLessOutlinedIcon fontSize="small" color="primary"/>
                    </IconButton>
                </div>
            </div>
            <div className={!frequencyDrawerOpen ? classes.dradrawerVisiblewer : classes.drawerClosed} >
                <div className={classes.panelIconOpen}>
                    <IconButton
                        color="primary"
                        aria-label="show frequencies"
                        component="span"
                        onClick={() => {
                            setFrequencyDrawerOpen(!frequencyDrawerOpen);
                        }}
                        size="large">
                        <EqualizerOutlinedIcon fontSize="small" color="primary"/>
                    </IconButton>
                </div>
            </div>
        </div>
    );
}

export default FrequencyDetailsDrawer;
