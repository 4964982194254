import React from 'react';

import ThreeWaySelection from '../ThreeWaySelection/ThreeWaySelection';

import makeStyles from '@mui/styles/makeStyles';
import { Grid, FormControlLabel, Typography, Slider, Switch } from '@mui/material';
import MultiSelectComboBox from '../MultiSelectComboBox/MultiSelectComboBox';

const useStyles = makeStyles((theme) => ({
    displayArea: {
        width: "100%",
        padding: "5px"
    },
  }));

export const SystemsMapDisplayOptions = ({isDisabled, onSliderChange, 
                                            onOverrideSelectionChange, overrideValue,
                                            onSelectTrunkedSys, trunkedSysSelection,
                                            onSelectTrunkedSites, trunkedSitesSelection,
                                            onSelectConventional, conventionalSelection,
                                            onServiceSelectionChange, 
                                            serviceChoices, serviceSelections, setServiceSelections}) => {
    const classes = useStyles();

    const sliderValueText = (value) => {
        //setMinRadius(value);
        //console.log("sliderUpdateRadius: "+value);
        return `${value} miles`;
    }

    return (
        <div className={classes.displayArea}>
            <Grid container direction="row" justifyContent="space-around" alignItems="baseline" spacing={1}>
                <Grid item sm={6}>
                    <Grid container direction="row" justifyContent="center" alignItems="center" spacing={1}>
                        <Grid item md={5} >
                                <Typography gutterBottom >Location Overrides:</Typography>
                        </Grid>
                        <Grid item md={5} >
                                <ThreeWaySelection name="showOverride"
                                                    kv1={["Show","show"]} 
                                                    kv2={["Hide","hide"]} 
                                                    kv3={["Only","only"]} 
                                                    value={overrideValue}
                                                    onChange={(e,v) => {
                                                        onOverrideSelectionChange(v); 
                                                    }}
                                />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item md={6}>
                    <Grid container direction="row" justifyContent="center" alignItems="center" spacing={1}>
                        <Grid item sm={3}>
                                <Typography gutterBottom >Min Radius:</Typography>
                        </Grid>
                        <Grid item md={7}>
                                <Slider disabled={isDisabled}
                                        defaultValue={0}
                                        step={25} min={0} max={200}
                                        marks
                                        getAriaValueText={sliderValueText}
                                        onChange={onSliderChange}
                                        aria-labelledby="discrete-slider-small-steps"
                                        valueLabelDisplay="auto"
                                />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <MultiSelectComboBox name="serviceTags" label="Service Tags"
                                isEditable={true}
                                choices={serviceChoices}
                                selection={serviceSelections}
                                handleChange={onServiceSelectionChange}
                                setFieldValue={setServiceSelections}
                                mapChoiceText={(item) => item.name}
            />
            <Grid container direction="row" justifyContent="space-around" alignItems="stretch">
                <Grid item>
                    <FormControlLabel disabled={isDisabled}
                                        control={<Switch name="Trunked" color="primary" size="small" checked={trunkedSysSelection} 
                                                    onChange={(e) => { onSelectTrunkedSys(e.target.checked);}} /> }
                                        label="Trunked Sys."
                    />
                </Grid>
                <Grid item>
                    <FormControlLabel disabled={isDisabled}
                                        control={<Switch name="TrunkSites" color="primary" size="small" checked={trunkedSitesSelection} 
                                                    onChange={(e) => { onSelectTrunkedSites(e.target.checked);}} /> }
                                        label="Trunked Sites"
                    />
                </Grid>
                <Grid item>
                    <FormControlLabel disabled={isDisabled}
                                        control={<Switch name="Conventional" color="primary" size="small" checked={conventionalSelection} 
                                                    onChange={(e) => { onSelectConventional(e.target.checked);}} /> }
                                        label="Conventional"
                    />
                </Grid>
            </Grid>
        </div>
    )
}

export default SystemsMapDisplayOptions;
