import React from 'react';

import withStyles from '@mui/styles/withStyles';
import { Dialog, IconButton, Typography } from '@mui/material';
import MuiDialogTitle from '@mui/material/DialogTitle';
import MuiDialogContent from '@mui/material/DialogContent';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

import { KeywordsEditor } from '..';

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
        '& h6': {
          color: theme.palette.primary.main
        }
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.primary.main,
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle className={classes.root} {...other}>
        <div><Typography variant="h6">{children}</Typography></div>
        {onClose ? (
            <IconButton
                aria-label="close"
                className={classes.closeButton}
                onClick={onClose}
                size="large">
                <CloseOutlinedIcon />
            </IconButton>
        ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);


export const KeywordsEditorDialog = ({open, handleCancel }) => {
    return(
        <div>
            <Dialog open={open} onClose={handleCancel} maxWidth="md" fullWidth={true} aria-labelledby="customized-dialog-title">
                <DialogTitle id="customized-dialog-title" onClose={handleCancel}>
                    Edit Keyword Lists
                </DialogTitle>
                <DialogContent dividers>
                    <KeywordsEditor /> 
                </DialogContent>
            </Dialog>
        </div>
    );
}

export default KeywordsEditorDialog