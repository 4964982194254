import React from 'react';

import { Container, Divider } from '@mui/material';


export const LandingPage = () => {
    return (
        <Container maxWidth="md">
            <Divider/>
            <h1> Landing Page. </h1>
            <Divider/>
        </Container>
    );
}

export default LandingPage;