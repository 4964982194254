import React from 'react';
import { connect } from 'react-redux'

import makeStyles from '@mui/styles/makeStyles';
import { Button, IconButton, Menu, MenuItem, Typography, Divider } from '@mui/material';

import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined';

import { KeywordsEditorDialog}  from '../';
import { ConfirmationDialog } from '../';

import cognitoUtils from '../../lib/cognitoUtils'

function mapStateToProps (state) {
    return { session: state.session }
}

function mapDispatchToProps (dispatch) {
    return {
        // empty place holder
    }
}

const useStyles = makeStyles((theme) => ({
    div: {
        display: 'inline',
        whiteSpace: 'nowrap'
    },
    root: {
        flexGrow: 1,
    },
    title: {
        flexGrow: 1,
    },
}));

export const NavBarUserMenu = (props) => {
    const classes = useStyles();

    const [anchorEl, setAnchorEl] = React.useState(null);

    const [keywordsDialogOpen, setKeywordsDialogOpen] = React.useState(false);

    const [confirmationDialogOpen, setConfirmationDialogOpen] = React.useState(false);
    const [confirmationDialogPrompt, setConfirmationDialogPrompt] = React.useState("");
    const [confirmationDialogActionName, setConfirmationDialogActionName] = React.useState("");
    const [confirmationDialogAction, setConfirmationDialogAction] = React.useState(null);

    const confirmAction = (prompt, actionName, callback) => {
        // CRITICAL SYNTAX: 
        //  Pass an anonymous function that returns the callback
        //  https://medium.com/swlh/how-to-store-a-function-with-the-usestate-hook-in-react-8a88dd4eede1
        setConfirmationDialogAction(() => callback ); 

        setConfirmationDialogPrompt(prompt);
        setConfirmationDialogActionName(actionName);
        setConfirmationDialogOpen(true);
    }

    const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
        event.stopPropagation();
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const openKeywordsDialog = () => {
        setKeywordsDialogOpen(true);
    };

    const closeKeywordsDialog = () => {
        setKeywordsDialogOpen(false);
    };

    //console.log("NavBarUserMenu: session=");
    //console.log(props.session);

    if(props.session.isLoggedIn) {
        return (
            <div className={classes.div}>
                <Typography variant="h6" display="inline">
                    {props.session.user.firstName+" "+props.session.user.lastName}
                </Typography>
                <IconButton
                    color="inherit"
                    aria-label="user settings"
                    component="span"
                    onClick={handleMenuClick}
                    onFocus={(event) => event.stopPropagation()}
                    size="large">
                    <ArrowDropDownOutlinedIcon fontSize="small"/>
                </IconButton>

                <Menu id="simple-menu" keepMounted
                        open={Boolean(anchorEl)}
                        anchorEl={anchorEl} 
                        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                        transformOrigin={{ vertical: "top", horizontal: "right" }}
                        onClose={handleMenuClose}
                >
                    <MenuItem id="menu-dashboard" onClick={() => {
                                    window.location.href = "/dashboard";
                                    handleMenuClose();
                                }}
                    >
                        Dashboard
                    </MenuItem>
                    <MenuItem id="menu-cache_status" onClick={() => {
                                    window.location.href = "/cache_status";
                                    handleMenuClose();
                                }}
                    >
                        Cache Status
                    </MenuItem>
                    <MenuItem id="menu-keywords" onClick={() => {
                                    openKeywordsDialog();
                                    handleMenuClose();
                                }}
                    >
                        Edit Keywords
                    </MenuItem>
                    <Divider/>
                    <MenuItem id="menu-logout" onClick={() => {
                                    //console.log("menu item:  confirming action");
                                    confirmAction("Are you sure you want to logout?", "Logout", () => {
                                        cognitoUtils.signOutCognitoSession();
                                        setConfirmationDialogOpen(false);
                                    });
                                }}
                    >
                        Logout
                    </MenuItem>
                </Menu>

                <KeywordsEditorDialog open={keywordsDialogOpen} 
                                        handleCancel={closeKeywordsDialog}
                />

                <ConfirmationDialog open={confirmationDialogOpen} 
                                        handleConfirm={confirmationDialogAction}
                                        handleCancel={() => { setConfirmationDialogOpen(false); }} 
                                        title={"Are you sure?"} 
                                        actionName={confirmationDialogActionName}
                                        message={confirmationDialogPrompt}
                />
            </div>
        );
    } else {
        return (
            <Button href={cognitoUtils.getCognitoSignInUri()} color="inherit" >
                Login
            </Button>
        );

    }
}


export default connect(mapStateToProps, mapDispatchToProps)(NavBarUserMenu)
