import { CognitoAuth } from 'amazon-cognito-auth-js/dist/amazon-cognito-auth'
import { CognitoUserPool } from 'amazon-cognito-identity-js'

import authParameters from '../config/authParameters.json'

let poolID = authParameters.UserPool;
let clientID = authParameters.UserPoolClient;
let userPoolBaseUri = authParameters.UserPoolAuthUri;
let callbackUri = authParameters.UserPoolCallbackUri;
let logoutUri = authParameters.UserPoolSignoutUri;

let tokenScopes = ['phone', 'email', 'profile','openid', 'aws.cognito.signin.user.admin'];

const convertCognitoSessionObject = (result) => {
  //console.log("convertCognitoSessionObject");
  //console.log("result=");
  //console.log(result);

  // Resolve the promise with the session credentials
  const session = {
    credentials: {
      accessToken: result.accessToken.jwtToken,
      idToken: result.idToken.jwtToken,
      refreshToken: result.refreshToken.token
    },
    user: {
      uid: result.idToken.payload['cognito:username'],
      userId: result.idToken.payload.email, // use the email address because that is unique and retained on import/export
      email: result.idToken.payload.email,
      firstName: result.idToken.payload.given_name,
      lastName: result.idToken.payload.family_name
    }
  }
  return session;
}

// Creates a CognitoAuth instance
const createCognitoAuth = () => {
  const appWebDomain = userPoolBaseUri.replace('https://', '').replace('http://', '')
  const auth = new CognitoAuth({
    UserPoolId: poolID,
    ClientId: clientID,
    AppWebDomain: appWebDomain,
    TokenScopesArray: tokenScopes,
    RedirectUriSignIn: callbackUri,
    RedirectUriSignOut: logoutUri
  })
  return auth
}

// Creates a CognitoUser instance
const createCognitoUser = () => {
  const pool = createCognitoUserPool()
  return pool.getCurrentUser()
}

// Creates a CognitoUserPool instance
const createCognitoUserPool = () => new CognitoUserPool({
  UserPoolId: poolID,
  ClientId: clientID
})

// Get the URI of the hosted sign in screen
const getCognitoSignInUri = () => {
  const signinUri = `https://${userPoolBaseUri}/login?response_type=code&client_id=${clientID}&redirect_uri=${callbackUri}`
  return signinUri
}

// Parse the response from a Cognito callback URI (assumed a token or code is in the supplied href). Returns a promise.
const parseCognitoWebResponse = (href) => {
  //console.log("UTILS: parseCognitoWebResponse");
  return new Promise((resolve, reject) => {
    const auth = createCognitoAuth()

    // userHandler will trigger the promise
    auth.userhandler = {
      onSuccess: function (result) {
        resolve(result)
      },
      onFailure: function (err) {
        reject(new Error('Failure parsing Cognito web response: ' + err))
      }
    }

    //console.log("UTILS: auth.parseCognitoWebResponse");
    auth.parseCognitoWebResponse(href)
  })
}

const loadExistingSession = () => {
  //console.log("loadExistingSession");
  const pool = createCognitoUserPool();
  const user = pool.getCurrentUser();
  const cognitoSession = (user != null) ? user.getSession(function(error, session) {return session}) : null;
  const session = (cognitoSession) ? convertCognitoSessionObject(cognitoSession) : null;

  //console.log("user=");
  //console.log(user);
  //console.log("cognitoSession=");
  //console.log(cognitoSession);
  //console.log("session=");
  //console.log(session);

  return session;
}

// Gets a new Cognito session. Returns a promise.
const getCognitoSession = () => {
  //console.log("UTILS: getCognitoSession");
  return new Promise((resolve, reject) => {
    const cognitoUser = createCognitoUser()
    cognitoUser.getSession((err, result) => {
      if (err || !result) {
        //console.log("UTILS: Failure getting Cognito session:");
        //console.log("Err="+err);
        //console.log("result=");
        //console.log(result);
        reject(new Error('Failure getting Cognito session: ' + err))
        return
      }

      //console.log("UTILS: Successfully got session: ");
      //console.log(result);

      // Resolve the promise with the session credentials
      const session = convertCognitoSessionObject(result);
      resolve(session)
    })
  })
}

// Sign out of the current session (will redirect to signout URI)
const signOutCognitoSession = () => {
  const auth = createCognitoAuth();
  auth.signOut();
}

const exports = {
  createCognitoAuth,
  createCognitoUser,
  createCognitoUserPool,
  loadExistingSession,
  getCognitoSession,
  getCognitoSignInUri,
  parseCognitoWebResponse,
  signOutCognitoSession
};

export default exports;
