const isAlNum = (c) => {
    return /^[a-z0-9]+$/i.test(c);
}

export const getSortedArrayByDictKey = (dict, propName) => {
    let array = [];
    for(let key in dict) {
        array.push(dict[key]);
    }
    let sorted = array.sort(
        // Sort punctuation characters ahead of alpha-numeric
        (a,b) => {
            const a_prop = a[propName];
            const b_prop = b[propName];
            //   compare a to b
            //   if a should come before b, return -1
            //   if a should come after b, return 1
            //   if a and b should be considered equal, return 0
            const a0 = (a_prop && a_prop.length > 0) ? a_prop[0] : '';
            const b0 = (b_prop && b_prop.length > 0) ? b_prop[0] : '';
            if(!isAlNum(a0) && !isAlNum(b0)) {
                return (a_prop > b_prop) ? 1 : -1;
            } else if(!isAlNum(a0) && isAlNum(b0)) {
                // a before b
                return -1;
            } else if(isAlNum(a0) && !isAlNum(b0)) {
                // a after b
                return 1;
            } else {
            //const aIsSpecial = !a0.is
                return (a_prop.toUpperCase() > b_prop.toUpperCase()) ? 1 : -1;
            }
        }
    );
    return sorted;
}
