import { Route, Switch } from 'react-router-dom';


import { ThemeProvider, StyledEngineProvider, createTheme } from '@mui/material/styles';

import makeStyles from '@mui/styles/makeStyles';

import { LandingPage, UserDashboard, CacheStatus, AuthenticationCallback, ErrorPage } from './pages';

import PrivateRoute from './components/PrivateRoute/PrivateRoute';
import NavBar from './components/NavBar/NavBar';

import cognitoUtils from './lib/cognitoUtils'

const theme = createTheme();

const useStyles = makeStyles((theme) => ({
  displayArea: {
    display: "flex",
    flexFlow: "column",
    height: "100vh",
    //border: "1px dotted #0313fc"
  },
  header: {
    /* sized to content */
    flexGrow: 0,
    flexShrink: 1,
    flexBasis: "auto",
    //border: "1px dotted #00ff00"
  },
  content: {
    /* fill remaining space */
    flexGrow: 1,
    flexShrink: 1,
    flexBasis: "auto",
    //border: "1px dotted #ff0000"
  },
}));

function App() {
  const classes = useStyles();

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
      <main>
        <div className={classes.displayArea}>
          <div className={classes.header}>
            <NavBar />
          </div>
          <div className={classes.content}>
            <Switch>
                <Route path="/" component={LandingPage} exact />
                <Route path='/login' 
                        component={() => { 
                            window.location.href = cognitoUtils.getCognitoSignInUri(); 
                            return null;
                        }}
                />
                <PrivateRoute path="/cache_status" component={CacheStatus} exact />
                <PrivateRoute path="/dashboard" component={UserDashboard} exact />
                <Route path="/auth_callback" component={AuthenticationCallback} exact />
                <Route component={ErrorPage} />
            </Switch>
          </div>
        </div>
      </main>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default App;
