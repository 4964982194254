// This is used to determine if a user is authenticated and
// if they are allowed to visit the page they navigated to.

// If they are: they proceed to the page
// If not: they are redirected to the login page.
import React from 'react'
import { connect } from 'react-redux'

import { Redirect, Route } from 'react-router-dom'

function mapStateToProps (state) {
    return { session: state.session }
}

function mapDispatchToProps (dispatch) {
    return {
        // empty place holder
    }
}
const PrivateRoute = ({ component: Component, ...rest }) => {
    let isLoggedIn = (rest.session && rest.session.isLoggedIn)

    //console.log("*** PrivateRoute ***");
    //console.log("rest=");
    //console.log(rest);
    //console.log("isLoggedIn="+isLoggedIn);
    //console.log("*********************");

    return (
        <Route {...rest}
                render={(props) => {
                    if(isLoggedIn) {
                        return <Component {...props} />
                    } else {
                        return <Redirect to={{ pathname: "/login", state: { referrer: props.location } }} />
                    }
                }}
        />
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(PrivateRoute)
