
import store from '../store'
import authParameters from '../config/authParameters.json'

const hostName = authParameters.UserPoolDomain;
const apiUrlBase = "https://api."+hostName

const listStatesUrl = apiUrlBase+"/data/states"
const listServicesUrl = apiUrlBase+"/data/services"

const getCacheStatusUrl = apiUrlBase+"/status/cache"
const getRefreshDatesUrl = apiUrlBase+"/status/refresh_date"

const baseProjectsURL = apiUrlBase+"/projects"
const listResultsURL = baseProjectsURL+"/results"
const createProjectURL = baseProjectsURL

const baseKeywordsURL = apiUrlBase+"/keywords"
const baseLocationsURL = apiUrlBase+"/locations"

//
// Redux binding to access the session store
//
function select(state) {
    //console.log("select(): state=");
    //console.log(state);
    if(state.session && state.session.credentials) {
        return state.session.credentials.idToken;
    }
    return "";
}

export const authenticatedFetch = (url, params = {method: 'GET'}) => {
    let token = select(store.getState());
    //console.log("authenticatedFetch:");
    //console.log("token="+token);
    //console.log("params=");
    //console.log(params);

    let headers = null;
    if(!params["headers"]) {
        headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Authorization', token);
        params["headers"] = headers;
    } else {
        headers = params["headers"];
        headers["Authorization"] = token;
    }
    
    //console.log("headers=");
    //console.log(headers);

    return fetch(url, params);
}

export const getUsStateList = () => {
    return authenticatedFetch(listStatesUrl).then( response => response.json() );
}

export const getServiceTagsList = () => {
    return authenticatedFetch(listServicesUrl).then( response => response.json() );
}

export const getCacheStatus = () => {
    return authenticatedFetch(getCacheStatusUrl).then( response => response.json() );
}

export const getUserProjectsList = () => {
    return authenticatedFetch(baseProjectsURL).then( response => response.json() );
}

export const getUserProjectArtifacts = (project_id = null) => {
    let url = listResultsURL;
    if(project_id) {
        url = url + "/" + project_id;
    }
    return authenticatedFetch(url).then( response => response.json() );
}

const projectValuesToParams = (values) => {
    let timestamp = Date.now();
    const params = {
        project_name: values.projectName,
        project_params: {
            state_code_list: values.stateCodes,
            service_id_list: values.serviceTags,
            keywords_id_list: values.keywordLists,
            exclusions_id_list: values.exclusionLists,
            matches_id_list: values.matchesLists,
            include_trunked: values.trunked,
            include_conventional: values.conventional,
            include_agencies: values.agencies,
            include_encrypted: values.encrypted,
            include_empty_locations: values.emptyLocations,
            add_counties: values.addCounties,
            abbreviate: values.abbreviate,
        },
        created: timestamp,
        modified: timestamp
    };

    return params;
}

// Helper to know when it's time to refresh a project
export const getDataRefreshDate = () => {
    return authenticatedFetch(getRefreshDatesUrl).then( response => response.json() );
}

// CRUD functions for projects

export const createNewProject = (values) => {
    let params = projectValuesToParams(values);
    
    let url = createProjectURL;
    return authenticatedFetch(url, {
                method: 'POST', // *GET, POST, PUT, DELETE, etc.
                cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                headers: {
                'Content-Type': 'application/json'
                },
                redirect: 'follow', // manual, *follow, error
                body: JSON.stringify(params) // body data type must match "Content-Type" header
            })
            .then( response => response.json() );
}

export const updateProject = (project_id, values) => {
    let params = projectValuesToParams(values);
    
    let url = createProjectURL+"/"+project_id;
    return authenticatedFetch(url, {
                method: 'POST', // *GET, POST, PUT, DELETE, etc.
                cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                headers: {
                'Content-Type': 'application/json'
                },
                redirect: 'follow', // manual, *follow, error
                body: JSON.stringify(params) // body data type must match "Content-Type" header
            })
            .then( response => response.json() );
}

export const deleteProject = (projectId) => {
    let url = baseProjectsURL+"/"+projectId;
    
    return authenticatedFetch(url, {
        method: 'DELETE', // *GET, POST, PUT, DELETE, etc.
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        redirect: 'follow', // manual, *follow, error
      });
}

export const deleteProjectPermanently = (projectId) => {
    let url = baseProjectsURL+"/"+projectId+"/delete_permanently";
    
    return authenticatedFetch(url, {
        method: 'DELETE', // *GET, POST, PUT, DELETE, etc.
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        redirect: 'follow', // manual, *follow, error
      });
}

export const runUserProject = (projectId) => {
    let url = baseProjectsURL+"/"+projectId+"/run";
    return authenticatedFetch(url);
}

export const summarizeProjectResults = (results_dict, projects_dict) => {
    let summary_items = {}
    let project_id_list = Object.keys(results_dict)
    for(var i = 0; i < project_id_list.length; i++) {
        let hpe_url = null;
        let map_url = null;
        let freq_url = null;
        let locations_url = null;
        let talkgroups_url = null;
        let project_id = project_id_list[i];
        let project = projects_dict[project_id];
        if(project) {
            let modified = projects_dict[project_id].modified;

            let project_results = results_dict[project_id];
            if(project_results) {
                let date_str = project_results["date_str"];
                let date_ms = project_results["created"];
                let file_list = project_results["file_list"];
                if(date_ms > modified) {
                    //console.log(file_list);

                    // Now find the URL for the HPE file
                    for(var j = 0; j < file_list.length; j++) {
                        let file_item = file_list[j];
                        let file_ext = file_item["file_ext"];
                        let file_name = file_item["file_name"];
                        if(file_ext === ".HPE") {
                            hpe_url = file_item["url"];
                        }
                        if(file_name === "frequencies.json") {
                            freq_url = file_item["url"];
                        }
                        if(file_name === "system_locations.json") {
                            locations_url = file_item["url"];
                        }
                        if(file_name === "talkgroups.json") {
                            talkgroups_url = file_item["url"];
                        }
                    }
                    // Compose the summary item dictionary and add it to the list
                    let summary_item = {
                        "project_id": project_id,
                        "date_str": date_str,
                        "created": date_ms,
                        "hpe_url": hpe_url,
                        "map_url": map_url,
                        "freq_url": freq_url,
                        "locations_url": locations_url,
                        "talkgroups_url": talkgroups_url
                    }
                    summary_items[project_id] = summary_item;
                } else {
                    console.log("ignoring old result")
                    console.log("date_ms="+date_ms+"; modified="+modified);
                    console.log(file_list);
                }
            }
        }
    }
    return summary_items;
}

//
// Keyword Lists
//
export const getUserKeywordLists = () => {
    return authenticatedFetch(baseKeywordsURL).then( response => response.json() );
}

export const createUserKeywordList = (values) => {
    const params = {
        list_name: values.listName,
        keyword_terms: values.keywordTerms,
        modified: Date.now()
    };
    
    return authenticatedFetch(baseKeywordsURL, {
                method: 'POST', // *GET, POST, PUT, DELETE, etc.
                cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                headers: {'Content-Type': 'application/json'},
                redirect: 'follow', // manual, *follow, error
                body: JSON.stringify(params) // body data type must match "Content-Type" header
            })
            .then( response => response.json() );
}

export const updateUserKeywordList = (listId, listName, terms) => {
    const params = {
        list_name: listName,
        keyword_terms: terms,
        modified: Date.now()
    };
    
    let url = baseKeywordsURL+"/"+listId;
    return authenticatedFetch(url, {
                method: 'POST', // *GET, POST, PUT, DELETE, etc.
                cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                headers: {'Content-Type': 'application/json'},
                redirect: 'follow', // manual, *follow, error
                body: JSON.stringify(params) // body data type must match "Content-Type" header
            })
            .then( response => response.json() );
}

export const deleteUserKeywordList = (listId) => {
    let url = baseKeywordsURL+"/"+listId;
    
    return authenticatedFetch(url, {
        method: 'DELETE', // *GET, POST, PUT, DELETE, etc.
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        redirect: 'follow', // manual, *follow, error
      });
}

//
// Location Overrides
//
export const getLocationOverrides = () => {
    return authenticatedFetch(baseLocationsURL).then( response => response.json() );
}

export const createLocationOverride = (matchId, matchLocation, overrideLocation) => {
    let timestamp = Date.now();
    const params = {
        override_location: overrideLocation,
        modified: timestamp
    };

    if(matchId != null) {
        params["match_id"] = matchId;
    }
    
    if(matchLocation != null) {
        params["match_location"] = matchLocation;
    }

    return authenticatedFetch(baseLocationsURL, {
                method: 'POST', // *GET, POST, PUT, DELETE, etc.
                cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                headers: {'Content-Type': 'application/json'},
                redirect: 'follow', // manual, *follow, error
                body: JSON.stringify(params) // body data type must match "Content-Type" header
            })
            .then( response => response.json() );
}

export const updateLocationOverride = (locationId, matchId, matchLocation, overrideLocation) => {
    let timestamp = Date.now();
    const params = {
        override_location: overrideLocation,
        modified: timestamp
    };

    if(matchId != null) {
        params["match_id"] = matchId;
    }
    
    if(matchLocation != null) {
        params["match_location"] = matchLocation;
    }
    
    let url = baseLocationsURL+"/"+locationId;
    return authenticatedFetch(url, {
                method: 'POST', // *GET, POST, PUT, DELETE, etc.
                cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                headers: {'Content-Type': 'application/json'},
                redirect: 'follow', // manual, *follow, error
                body: JSON.stringify(params) // body data type must match "Content-Type" header
            })
            .then( response => response.json() );
}

export const deleteLocationOverride = (locationId) => {
    let url = baseLocationsURL+"/"+locationId;
    
    return authenticatedFetch(url, {
        method: 'DELETE', // *GET, POST, PUT, DELETE, etc.
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        redirect: 'follow', // manual, *follow, error
      });
}
