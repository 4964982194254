import React from 'react';

import makeStyles from '@mui/styles/makeStyles';
import { AppBar, Toolbar, Typography } from '@mui/material';

import NavBarUserMenu from '../NavBarUserMenu/NavBarUserMenu';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  title: {
    flexGrow: 1,
  },
}));

export const NavBar = () => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
        <AppBar position="static">
            <Toolbar>
                <Typography variant="h4" className={classes.title}>
                    ScannerScout.com
                </Typography>
                <NavBarUserMenu/>
            </Toolbar>
        </AppBar>
        </div>
    );
}

export default NavBar;
