import React from 'react';
import { useState, useEffect } from 'react';

import { getUsStateList, getServiceTagsList, getUserKeywordLists } from '../lib/apiEndpoints';
import { getUserProjectsList, getUserProjectArtifacts, summarizeProjectResults } from '../lib/apiEndpoints';
import { createNewProject, updateProject, runUserProject } from '../lib/apiEndpoints';
import { getLocationOverrides, createLocationOverride, updateLocationOverride, deleteLocationOverride } from '../lib/apiEndpoints';
import { getDataRefreshDate } from '../lib/apiEndpoints';

import { getSortedArrayByDictKey } from '../lib/helperUtils';

import { THREEWAY_STATE_EXCLUDE } from '../constants/toggleStates';

import { dictKeyFromLocation, locationObjectFromDict } from '../lib/locationHelpers';

import { useInterval } from '../hooks/useInterval';

import { Loader, ProjectList, SystemsDetails } from '../components';
import { ProjectDetailsDialog }  from '../components';

import makeStyles from '@mui/styles/makeStyles';

import { Box, Container, Grid, Button } from '@mui/material';
import { Menu, MenuItem, IconButton } from '@mui/material';

import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';

const useStyles = makeStyles((theme) => ({
    outerBorder: {
        margin: '0px',
        borderWidth: '1px',
        borderStyle: "solid", 
        borderColor: theme.palette.primary.main
    },
    displayArea: {
        display: "flex",
        flexFlow: "column",
        height: "100%",
    },
    fixedSize: {
        /* sized to content */
        flexGrow: 0,
        flexShrink: 1,
        flexBasis: "auto",
    },
    expandToRemainder: {
        /* fill remaining space */
        flexGrow: 1,
        flexShrink: 1,
        flexBasis: "auto",
    },
  }));

export const UserDashboard = () => {
    const classes = useStyles();

    const emptyProjectParamValues = {
        state_code_list: [],
        service_id_list: [],
        include_trunked: true,
        include_conventional: true,
        include_agencies: true,
        include_encrypted: THREEWAY_STATE_EXCLUDE,
        include_empty_locations: THREEWAY_STATE_EXCLUDE,
    };

    // HACK:  Passing this prop to trigger SystemsMap update
    const [dirtyBit, setDirtyBit] = useState(0);

    const [staticData, setStaticData] = useState({
        usStates: {data: [], loaded: false, error: false},
        serviceTags: {data: [], loaded: false, error: false},
        keywordLists: {data: [], loaded: false, error: false},
        locationOverrides: {data: [], loaded: false, error: false},
    });

    const [projects, setProjects] = useState(null);
    const [artifacts, setArtifacts] = useState(null);
    const [summaries, setSummaries] = useState(null);

    const [refreshDate, setRefreshDate] = useState(0);
    
    const [fileData, setFileData] = useState({
        frequency: {},
        location: {},
        talkgroup: {}
    });

    const [projectDialogOpen, setProjectDialogOpen] = useState(false);

    const [activeProjectName, setActiveProjectName] = useState("");
    const [activeProjectId, setActiveProjectId] = useState(null);
    const [activeProjectParams, setActiveProjectParams] = useState(emptyProjectParamValues);

    const [projectsToPoll, setProjectsToPoll] = useState([]);

    const [selectedProject, setSelectedProject] = React.useState({
        project_id: null,
        frequencies: null,
        locations: null
    });

    const [anchorElTemplate, setAnchorElTemplate] = React.useState(null);

    const project_templates = [
        {
            "name": "<TEMPLATE> Airports",
            "params": {
                "exclusions_id_list": [
                    "DoD",
                    "Railroad",
                    "Utilities and Industrial"
                ],
                "matches_id_list": [
                    "Airports"
                ],
                "service_id_list": [
                    15, 17, 37, 35, 36,
                    4, 9, 25, // EMS
                    29, 16, 
                    3, 8, 24, // Fire
                    13, 12, 11, 
                    2, 7, 23, // Law 
                    31, 30, 
                    1, 6, 22, // Multi Dispatch/Tac/Talk
                    21, 14, 20, 32, 33, 26, 34
                ]
            }
        },
        {
            "name": "<TEMPLATE> Dispatch",
            "params": {
                "exclusions_id_list": [
                    "Airports",
                    "Court",
                    "DoD",
                    "Gov Misc",
                    "Hotels",
                    "Marine and Beach",
                    "Other",
                    "Railroad",
                    "Retail",
                    "Tactical",
                    "Training",
                    "Transportation",
                    "University",
                    "Utilities and Industrial",
                    "Wildlife",
                ],
                "matches_id_list": [],
                "service_id_list": [
                    4, // EMS Dispatch
                    3, // Fire Dispatch
                    2, // Law Dispatch
                    1  // Multi Dispatch
                ]
            }
        },
        {
            "name": "<TEMPLATE> EMS",
            "params": {
                "exclusions_id_list": [
                    "Airports",
                    "Court",
                    "DoD",
                    "Gov Misc",
                    "Hotels",
                    "Local PD",
                    "Marine and Beach",
                    "Other",
                    "Railroad",
                    "Retail",
                    "State Police",
                    "Tactical",
                    "Training",
                    "Transportation",
                    "University",
                    "Utilities and Industrial",
                    "Wildlife",
                ],
                "matches_id_list": [],
                "service_id_list": [
                    4, 9, 25
                ]
            }
        },
        {
            "name": "<TEMPLATE> Fire",
            "params": {
                "exclusions_id_list": [
                    "Airports",
                    "Court",
                    "DoD",
                    "Gov Misc",
                    "Hotels",
                    "Local PD",
                    "Marine and Beach",
                    "Other",
                    "Railroad",
                    "Retail",
                    "State Police",
                    "Tactical",
                    "Training",
                    "Transportation",
                    "University",
                    "Utilities and Industrial",
                    "Wildlife",
                ],
                "matches_id_list": [],
                "service_id_list": [
                    3, 8, 24
                ]
            }
        },
        {
            "name": "<TEMPLATE> Fire and EMS",
            "params": {
                "exclusions_id_list": [
                    "Airports",
                    "Court",
                    "DoD",
                    "Gov Misc",
                    "Hotels",
                    "Local PD",
                    "Marine and Beach",
                    "Other",
                    "Railroad",
                    "Retail",
                    "State Police",
                    "Tactical",
                    "Training",
                    "Transportation",
                    "University",
                    "Utilities and Industrial",
                    "Wildlife",
                ],
                "matches_id_list": [],
                "service_id_list": [
                    4, 9, 25, // Fire
                    3, 8, 24  // EMS
                ]
            }
        },
        {
            "name": "<TEMPLATE> Hotels",
            "params": {
                "exclusions_id_list": [
                    "DoD",
                    "Prisons",
                    "IGNORE Hotels",
                ],
                "matches_id_list": [
                    "Hotels"
                ],
                "service_id_list": [
                    15, 17, 37, 35, 36,
                    4, 9, 25, // EMS
                    29, 16, 
                    3, 8, 24, // Fire
                    13, 12, 11, 
                    2, 7, 23, // Law 
                    31, 30, 
                    1, 6, 22, // Multi Dispatch/Tac/Talk
                    21, 14, 20, 32, 33, 26, 34
                ]
            }
        },
        {
            "name": "<TEMPLATE> Law",
            "params": {
                "exclusions_id_list": [
                    "Airports",
                    "Court",
                    "DoD",
                    "Gov Misc",
                    "Hotels",
                    "Marine and Beach",
                    "Other",
                    "Railroad",
                    "Retail",
                    "Tactical",
                    "Training",
                    "Transportation",
                    "University",
                    "Utilities and Industrial",
                    "Wildlife",
                ],
                "matches_id_list": [],
                "service_id_list": [
                    2, 7, 23
                ]
            }
        },
        {
            "name": "<TEMPLATE> Local PD",
            "params": {
                "exclusions_id_list": [
                    "Airports",
                    "Court",
                    "DoD",
                    "Gov Misc",
                    "Hotels",
                    "Marine and Beach",
                    "Other",
                    "Railroad",
                    "Retail",
                    "State Police",
                    "Tactical",
                    "Training",
                    "Transportation",
                    "University",
                    "Utilities and Industrial",
                    "Wildlife",
                ],
                "matches_id_list": [],
                "service_id_list": [
                    2, 7, 23
                ]
            }
        },
        {
            "name": "<TEMPLATE> State Police",
            "params": {
                "exclusions_id_list": [
                    "Airports",
                    "Court",
                    "DoD",
                    "Gov Misc",
                    "Hotels",
                    "Local PD",
                    "Marine and Beach",
                    "Other",
                    "Railroad",
                    "Retail",
                    "Tactical",
                    "Training",
                    "Transportation",
                    "University",
                    "Utilities and Industrial",
                    "Wildlife",
                ],
                "matches_id_list": [
                    "State Police"
                ],
                "service_id_list": [
                    2, 7, 23
                ]
            }
        },
    ];

    const handleTemplateMenuClick = (event) => {
        setAnchorElTemplate(event.currentTarget);
        event.stopPropagation();
    }

    const handleTemplateMenuClose = () => {
        setAnchorElTemplate(null);
    }

    const openNewProjectDialog = () => {
        setActiveProjectName("");
        setActiveProjectId(null);
        setActiveProjectParams(emptyProjectParamValues);
        setProjectDialogOpen(true);
    };

    const openCloneProjectDialog = (project_id) => {
        const name = projects[project_id].projectName + " (COPY)";
        const params = projects[project_id].parameters;

        setActiveProjectName(name);
        setActiveProjectId(null);
        setActiveProjectParams(params);
        setProjectDialogOpen(true);
    };

    const openEditProjectDialog = (project_id) => {
        const name = projects[project_id].projectName;
        const params = projects[project_id].parameters;

        //console.log("EDIT PROJECT:  project_id="+project_id);
        //console.log("EDIT PROJECT:  params=");
        //console.log(params);

        setActiveProjectName(name);
        setActiveProjectId(project_id);
        setActiveProjectParams(params);
        setProjectDialogOpen(true);
    };

    const openNewProjectDialogByTemplate = (template_params) => {
        const name = template_params.name;
        const exclusions_id_list = get_keyword_id_list_by_name_list(template_params.params.exclusions_id_list);
        const matches_id_list = get_keyword_id_list_by_name_list(template_params.params.matches_id_list);
        const params = {
            "include_conventional": true,
            "include_trunked": true,
            "include_agencies": true,
        
            "include_encrypted": 0,
            "include_empty_locations": 0,

            "exclusions_id_list": exclusions_id_list,
            "matches_id_list": matches_id_list,
            "service_id_list": template_params.params.service_id_list
        }

        //console.log("TEMPLATE PROJECT:  params=");
        //console.log(params);

        setActiveProjectName(name);
        setActiveProjectId(null);
        setActiveProjectParams(params);
        setProjectDialogOpen(true);
    };

    const handleCancelNewProject = () => {
        setProjectDialogOpen(false);
    };

    async function fetchRefreshDate() {           
        await getDataRefreshDate()
                .then( data => {
                    //console.log("refresh date");
                    //console.log(data);
                    const completion = data.completion;
                    setRefreshDate(completion);
                })
                .catch(error => {
                    console.log("Uh oh")
                    console.log(error);
                    setRefreshDate(0);
                });
    }

    async function fetchServiceTags() {           
        await getServiceTagsList()
                .then( data => {
                    //console.log("service tag list");
                    //console.log(data.tag_list);
                    const newState = { ...staticData };
                    newState.serviceTags.data = data.tag_list;
                    newState.serviceTags.loaded = true;
                    setStaticData(newState);
                })
                .catch(error => {
                    console.log("Uh oh")
                    console.log(error);
                    const newState = { ...staticData };
                    newState.serviceTags.error = true;
                    newState.serviceTags.loaded = true;
                    setStaticData(newState);
                });
    }

    async function fetchUsStates() {           
        await getUsStateList()
                .then( data => {
                    //console.log("us state list");
                    const newState = { ...staticData };
                    newState.usStates.data = data.state_list;
                    newState.usStates.loaded = true;
                    setStaticData(newState);
                })
                .catch(error => {
                    console.log("Uh oh")
                    console.log(error);
                    const newState = { ...staticData };
                    newState.usStates.error = true;
                    newState.usStates.loaded = true;
                    setStaticData(newState);
                });
    }

    const get_keyword_list_id_by_name = (list_name) => {
        const lists = staticData.keywordLists.data;
        for(let i = 0; i < lists.length; i++) {
            const item = lists[i];
            if(item.name === list_name) {
                //console.log("Matched "+list_name+" to id "+item.id);
                return item.id;
            }
        }
        console.log("ERROR:  Unable to match keyword list by name: '"+list_name+"'");
        console.log("keyword lists=");
        console.log(staticData.keywordLists.data);
        return null;
    }

    const get_keyword_id_list_by_name_list = (list_of_names) => {
        let id_list = [];
        for(let i = 0; i < list_of_names.length; i++) {
            const name = list_of_names[i];
            const id = get_keyword_list_id_by_name(name);
            if(id) {
                id_list.push(id);
            }
        }
        return id_list;
    }

    async function fetchKeywordLists() {           
        await getUserKeywordLists()
                .then( data => {
                    //console.log("user keyword lists");
                    
                    //console.log(data.keyword_lists_dict);
                    let idList = Object.keys(data.keyword_lists_dict);

                    let list = [];
                    for(let i = 0; i < idList.length; i++) {
                        let id = idList[i];
                        let name = data.keyword_lists_dict[id].listName;
                        let modified = data.keyword_lists_dict[id].modified;
                        let item = {"id": id, "name": name, "modified": modified};
                        //console.log(item);
                        list.push(item);
                    }

                    // Put them in ascending order by name of the list
                    list.sort((a, b) => (a.name > b.name) ? 1 : -1)

                    const newState = { ...staticData };
                    newState.keywordLists.data = list;
                    newState.keywordLists.loaded = true;
                    setStaticData(newState);
                    //console.log("staticData=");
                    //console.log(newState);
                })
                .catch(error => {
                    console.log("Uh oh")
                    console.log(error);
                    const newState = { ...staticData };
                    newState.keywordLists.error = true;
                    newState.keywordLists.loaded = true;
                    setStaticData(newState);
                });
    }

    async function fetchLocationOverrides() {           
        await getLocationOverrides()
                .then( data => {
                    //console.log("location overrides");
                    //console.log(data.locations_list);
                    let dict = {};
                    for(let i = 0; i < data.locations_list.length; i++) {
                        let override = data.locations_list[i];
                        dict[override.locationId] = override;

                        let location = locationObjectFromDict(override.matchLocation);
                        if(location) {
                            let key = dictKeyFromLocation(location);
                            dict[key] = override;
                        }

                        if(override.matchId) {
                            dict[override.matchId] = override;
                        }
                    }
                    const newState = { ...staticData };
                    newState.locationOverrides.data = dict;
                    newState.locationOverrides.loaded = true;
                    setStaticData(newState);
                })
                .catch(error => {
                    console.log("Uh oh")
                    console.log(error);
                    const newState = { ...staticData };
                    newState.locationOverrides.error = true;
                    newState.locationOverrides.loaded = true;
                    setStaticData(newState);
                });
    }

    async function fetchProjectsList() {  
        await getUserProjectsList()
                .then( data => {
                    //console.log("project list");
                    //console.log(data.projects_dict);
                    setProjects(data.projects_dict);
                })
                .catch(error => {
                    console.log("Uh oh - error loading project list")
                    console.log(error);
                    setProjects(null);
                });
    }

    async function parallelfetchArtifactsAndSummaries(projects_dict) {  
        let artifacts_dict = {};
        let promises = [];

        let ids = Object.keys(projects_dict);
        for (const i in ids) {
            const project_id = ids[i];
            let promise = getUserProjectArtifacts(project_id)
                            .then( data => {
                                artifacts_dict[project_id] = data.results_dict[project_id];
                            })
                            .catch(error => {
                                console.log("Uh oh - error loading artifacts for project_id="+project_id);
                                console.log(error);
                                setArtifacts(null);
                                setSummaries(null);
                            });
            promises.push(promise);
        }

        await Promise.all(promises);

        setArtifacts(artifacts_dict);

        let summarized = summarizeProjectResults(artifacts_dict, projects_dict)
        setSummaries(summarized);
    }

    async function fetchArtifactsAndSummaries(projects_dict) {  
        if(projects_dict) {
            //await bulkfetchArtifactsAndSummaries(projects_dict);
            await parallelfetchArtifactsAndSummaries(projects_dict);
        }
    }

    const postprocessFrequencyData = (data) => {
        //console.log(data);
        let keys = Object.keys(data.by_frequency);

        let x_vals = [];
        keys.forEach( val => x_vals.push(parseInt(val)) );
        x_vals.sort((a, b) => a - b);

        let x_vals_downscale = []
        x_vals.forEach( val => x_vals_downscale.push(val/1000000.0) );

        let y_vals_counts = [];
        x_vals.forEach( val => y_vals_counts.push(data.by_frequency[val.toString()].Counter) );
        let y_vals_names = [];
        x_vals.forEach( val => y_vals_names.push(data.by_frequency[val.toString()].Names.length) );

        let hovertext = [];
        x_vals.forEach( val => {
            let mhz = val/1000000.0
            let count = data.by_frequency[val.toString()].Names.length;
            let text = mhz+"MHz: "+count.toString()+" items";
            hovertext.push(text) 
        });

        const frequency_data = {
            data: data,
            x_vals: x_vals,
            x_vals_downscale: x_vals_downscale,
            x_min: x_vals_downscale[0],
            x_max: x_vals_downscale[x_vals_downscale.length-1],
            y_vals_counts: y_vals_counts,
            y_vals_names: y_vals_names,
            hovertext: hovertext,
        }
        //console.log(frequency_data);

        return frequency_data;
    }

    function fetchLocationAndFrequencyDataForProject(project_id, location_url, freqency_url, talkgroups_url, result_dict) {
        let location_fetch = fetch(location_url)
                                .then( response => response.json() )
                                .then( data => {
                                    //console.log("location data:");
                                    //console.log(data);
                                    result_dict.location[project_id] = data;
                                })
                                .catch(error => {
                                    console.log("Uh oh")
                                    console.log(error);
                                });

        let frequency_fetch = fetch(freqency_url)
                                .then( response => response.json() )
                                .then( data => {
                                    const frequency_data = postprocessFrequencyData(data);
                                    result_dict.frequency[project_id] = frequency_data;
                                })
                                .catch(error => {
                                    console.log("Uh oh")
                                    console.log(error);
                                });

        let talkgroup_fetch = fetch(talkgroups_url)
                                .then( response => response.json() )
                                .then( data => {
                                    //console.log("talkgroup data:");
                                    //console.log(data);
                                    result_dict.talkgroup[project_id] = data;
                                })
                                .catch(error => {
                                    console.log("Uh oh")
                                    console.log(error);
                                });

        return [location_fetch, frequency_fetch, talkgroup_fetch];
    }

    async function preloadLocationAndFrequencyData(projects_dict, summaries_dict, top_n) {
        if(projects_dict && summaries_dict) {
            let results_dict = {
                location: {},
                frequency: {},
                talkgroup: {}
            }

            let projects = Object.values(projects_dict);
            let sorted = getSortedArrayByDictKey(projects, "projectName");

            let promises = [];
            for(let i = 0; i < Math.min(top_n, sorted.length); i++) {
                let project = sorted[i];
                let project_id = project.projectId;
                let summary = summaries_dict[project_id];
                if(summary) {
                    if(summary.hasOwnProperty("locations_url") && summary.hasOwnProperty("freq_url") && summary.hasOwnProperty("talkgroups_url")) {
                        let location_url = summary.locations_url;
                        let frequency_url = summary.freq_url
                        let talkgroup_url = summary.talkgroups_url

                        if(location_url && frequency_url) {
                            let promise_array = fetchLocationAndFrequencyDataForProject(project_id, location_url, frequency_url, talkgroup_url, results_dict);
                            promises.concat(promise_array);
                        }
                    }
                }
            }

            // Wait for all requests to complete
            await Promise.all(promises);

            setFileData(results_dict);
        }
    }

    async function onDemandLoadLocationAndFrequencyData(project_id, summaries_dict, results_dict) {
        if(summaries_dict) {
            let summary = summaries_dict[project_id];
            let location_url = summary.locations_url;
            let frequency_url = summary.freq_url
            let talkgroup_url = summary.talkgroups_url

            let promise_array = fetchLocationAndFrequencyDataForProject(project_id, location_url, frequency_url, talkgroup_url, results_dict);

            // Wait for all requests to complete
            await Promise.all(promise_array);
        }
    }

    // Load all projects & static data when page first loads
    useEffect(() => {
        fetchProjectsList();

        fetchRefreshDate();

        fetchServiceTags();
        fetchUsStates();
        fetchKeywordLists();
        fetchLocationOverrides();
    }, []);

    useEffect(() => {
        fetchArtifactsAndSummaries(projects);
    }, [projects])

    useEffect(() => {
        preloadLocationAndFrequencyData(projects, summaries, 5);

        // Refresh the list of projects missing artifacts whenever the data state changes
        refreshMissingArtifactsList(projects, summaries);
    }, [summaries])

    // Check for new artifacts (assuming we are missing some) on a regular interval
    let delay = 10*1000;
    useInterval(async() => {
        if(projectsToPoll.length > 0) {
            //console.log("polling for project artifacts. "+projectsToPoll.length+" projects");
            fetchProjectsList();
        }
    }, delay)

    const refreshMissingArtifactsList = (projects_dict, summaries_dict) => {
        //console.log("+++ refreshMissingArtifactsList");
        if(projects_dict && summaries_dict) {
            let projectsMissingArtifacts = [];
            for(let i = 0; i < Object.keys(projects_dict).length; i++) {
                let project_id = Object.keys(projects_dict)[i];
                if(!(project_id in summaries_dict)) {
                    let project = projects_dict[project_id];
                    if(!project.status || project.status.code === 0) {
                        console.log("Missing artifacts for: "+project.projectName+"; "+project_id);
                        console.log(project);
                        projectsMissingArtifacts.push(project_id);
                    }
                } else {
                    let project = projects_dict[project_id];
                    let summary = summaries_dict[project_id];

                    const recentlyModifed = (project.modified > summary.created);
                    const statusProcessing = (project.status && project.status.code === 0);
                    const generatedBeforeLastRun = (project.generated < project.last_run);
                    const currentlyProcessing = (statusProcessing || generatedBeforeLastRun);

                    /*
                    console.log("-----------------");
                    console.log(project.projectName);
                    console.log(project);
                    console.log("project.modified= "+project.modified);
                    console.log("summary.created= "+summary.created);
                    console.log("recentlyModifed= "+recentlyModifed);
                    console.log("statusProcessing= "+statusProcessing);
                    console.log("generatedBeforeLastRun= "+generatedBeforeLastRun);
                    console.log("currentlyProcessing= "+currentlyProcessing);
                    */

                    if(recentlyModifed || currentlyProcessing) {
                        console.log("Refreshing artifacts for: "+project.projectName+"; "+project_id);
                        console.log(project);
                        projectsMissingArtifacts.push(project_id);
                    }
                }
            }
            setProjectsToPoll(projectsMissingArtifacts);
        }
        //console.log("--- refreshMissingArtifactsList");
    }

    const addLocationOverrideToStaticData = (entry) => {
        let location = locationObjectFromDict(entry.matchLocation);

        const newStaticData = { ...staticData };
        newStaticData.locationOverrides.data[entry.location_id] = entry;
        if(location) {
            let key = dictKeyFromLocation(location);
            newStaticData.locationOverrides.data[key] = entry;
        }
        if(entry.matchId) {
            newStaticData.locationOverrides.data[entry.matchId] = entry;
        }
        setStaticData(newStaticData);
    }

    const removeLocationOverrideFromStaticData = (entry) => {
        let overrides = staticData.locationOverrides.data;

        let location = locationObjectFromDict(entry.matchLocation);
        if(location) {
            let key = dictKeyFromLocation(location);
            delete overrides[key];
        }
        if(entry.matchId) {
            delete overrides[entry.matchId];
        }
        delete overrides[entry.location_id];
        
        const newStaticData = { ...staticData };
        newStaticData.locationOverrides.data = overrides;
        setStaticData(newStaticData);
    }

    const handleProjectSave = async (project_id, values) => {
        console.log("SAVE PROJECT!");

        console.log("project values:");
        console.log(values);

        if(!project_id) {
            console.log("** CREATE NEW PROJECT");
            // Create based on the values from the config dialog
            createNewProject(values)
                .then( data => {
                    let project = data.project;
                    project_id = project.projectId
                    console.log("projectId="+project_id);

                    // Append the new project so that it is rendered
                    const new_projects = Object.assign({}, projects);
                    new_projects[project_id] = project;
                    setProjects(new_projects);
        
                    // Close the dialog after project has been created
                    setProjectDialogOpen(false);

                    // And now run it
                    runUserProject(project_id)
                        .then( data => {
                            console.log("Running projectId="+project_id);
                        })
                        .catch(error => {
                            console.log("ERROR RUNNING NEW PROJECT")
                            console.log(error);
                        });
                })
                .catch(error => {
                    console.log("ERROR CREATING PROJECT")
                    console.log(error);
                });
        } else {
            console.log("** UPDATE EXISTING PROJECT");
            updateProject(project_id, values)
                .then( data => {
                    console.log("data=");
                    console.log(data);
                    let project = data.project;

                    // Update the new project in our state
                    const new_projects = Object.assign({}, projects);
                    new_projects[project_id] = project;
                    setProjects(new_projects);

                    // Remove the old (no longer valid) artifact & summary data
                    const new_artifacts = Object.assign({}, artifacts);
                    delete new_artifacts[project_id];
                    setArtifacts(new_artifacts);
                    const new_summaries = Object.assign({}, summaries);
                    delete new_summaries[project_id];
                    setSummaries(new_summaries);
        
                    // Close the dialog after project has been updated
                    setProjectDialogOpen(false);

                    // And now run it
                    runUserProject(project_id)
                        .then( data => {
                            console.log("Running projectId="+project_id);
                        })
                        .catch(error => {
                            console.log("ERROR RUNNING NEW PROJECT")
                            console.log(error);
                        });
                })
                .catch(error => {
                    console.log("ERROR UPDATING PROJECT")
                    console.log(error);
                });
        }
    };

    async function handleProjectRefresh(project_id) {
        //console.log("REFRESH PROJECT!");
        try {
            let project = projects[project_id];
            //console.log("Existing project data=");
            //console.log(project);

            // And now run it
            runUserProject(project_id)
                .then( data => {
                    //console.log("Running projectId="+project_id);
                    //console.log("Run project response=");
                    //console.log(data);
                })
                .catch(error => {
                    console.log("ERROR RUNNING NEW PROJECT")
                    console.log(error);
                });

            // Remove the old (no longer valid) artifact data
            const new_artifacts = Object.assign({}, artifacts);
            delete new_artifacts[project_id];
            setArtifacts(new_artifacts);

            // Update the project last run date
            const last_run = Date.now();
            project.last_run = last_run;
            project.status = {code: 0, message: "Processing"};

            // Save the new project in our state
            const new_projects = Object.assign({}, projects);
            new_projects[project_id] = project;
            setProjects(new_projects);
        }
        catch(error) {
            console.log("ERROR REFRESHING PROJECT")
            console.log(error);
        }
    };

    const handleProjectDeleted = (project_id) => {
        // Delete the project from the existing dictionary
        const new_projects = Object.assign({}, projects);
        delete new_projects[project_id];
        setProjects(new_projects);
    };

    async function handleProjectSelectionChange(project_id) {
        //console.log("handleProjectSelectionChange: project_id="+project_id);
        const newSelection = {
            project_id: project_id, 
            locations: null,
            frequencies: null,
            talkgroups: null
        }

        // Is this already in the local cache?
        if((project_id in fileData.location) && (project_id in fileData.frequency && (project_id in fileData.talkgroup))) {
            newSelection.locations = fileData.location[project_id];
            newSelection.frequencies = fileData.frequency[project_id];
            newSelection.talkgroups = fileData.talkgroup[project_id];
            setSelectedProject(newSelection);
        } else if (summaries) {
            // Nope.  Load the location & frequency data for this particular project
            let results_dict = {
                location: {},
                frequency: {},
                talkgroup: {}
            }

            await onDemandLoadLocationAndFrequencyData(project_id, summaries, results_dict)
                    .then(results => {
                        const location = results_dict.location[project_id];
                        const frequency = results_dict.frequency[project_id];
                        const talkgroup = results_dict.talkgroup[project_id];
            
                        newSelection.locations = location;
                        newSelection.frequencies = frequency;
                        newSelection.talkgroups = talkgroup;
                        setSelectedProject(newSelection);
            
                        const newState = { ...fileData };
                        newState.location[project_id] = location;
                        newState.frequency[project_id] = frequency;
                        newState.talkgroup[project_id] = talkgroup;
                        setFileData(newState);
                    });
        } 

    }

    async function handleAddOverride(id, match, replace) {
        console.log("handleAddOverride");
        await createLocationOverride(id, match, replace)
                .then(data => {
                    //console.log(data);
                    let locationId = data.locationId;
                    //console.log("new locationId="+locationId);
                    
                    let entry = {
                        location_id: locationId,
                        matchId: id,
                        matchLocation: match,
                        overrideLocation: replace,
                        modified: Date.now()
                    }

                    addLocationOverrideToStaticData(entry);
                })
                .then(data => {
                    // HACK:  Not sure how else to trigger SystemsMap update
                    //        useEffect() on the overrides isn't working...
                    setDirtyBit(Math.floor(Math.random() * 10000));
                })
                .catch(error => {
                    console.log("Error creating new location override")
                    console.log(error);
                });
    }

    async function handleUpdateOverride(location_id, id, match, replace) {
        console.log("handleUpdateOverride");
        await updateLocationOverride(location_id, id, match, replace)
                .then(data => {
                    console.log(data);
                    if(location_id in staticData.locationOverrides.data) {
                        let entry = staticData.locationOverrides.data[location_id];

                        // Remove all old values from static data before updating
                        removeLocationOverrideFromStaticData(entry);
                        
                        entry.id = id;
                        entry.matchLocation = match;
                        entry.overrideLocation = replace;

                        addLocationOverrideToStaticData(entry);
                    } else {
                        console.log("couldn't find location_id in location override data:  "+location_id);
                    }
                })
                .then(data => {
                    // HACK:  Not sure how else to trigger SystemsMap update
                    //        useEffect() on the overrides isn't working...
                    setDirtyBit(Math.floor(Math.random() * 10000));
                })
                .catch(error => {
                    console.log("Error updating location override")
                    console.log(error);
                });
    }

    async function handleDeleteOverride(location_id) {
        console.log("handleDeleteOverride");
        await deleteLocationOverride(location_id)
                .then(data => {
                    //console.log(data);
                    let overrides = staticData.locationOverrides.data;
                    let entry = overrides[location_id];

                    removeLocationOverrideFromStaticData(entry);
                })
                .then(data => {
                    // HACK:  Not sure how else to trigger SystemsMap update
                    //        useEffect() on the overrides isn't working...
                    setDirtyBit(Math.floor(Math.random() * 10000));
                })
                .catch(error => {
                    console.log("Error deleting location override")
                    console.log(error);
                });
    }

    if(!projects) {
        return (
            <div className={classes.root}>
                <Loader />
            </div>
        );
    } else {
        return (
            <Container maxWidth="xl" style={{ height: '100%' }}>
                <Grid container direction="row" alignItems="stretch" justifyContent="space-between" style={{ height: '100%' }}>
                    <Grid item xs={3} className={classes.displayArea} style={{ display: "flex", flexDirection: "column" }}>
                        <Box m={1}>
                            <Grid container alignItems="center">
                                <Grid item xs={10} >
                                    <Button variant="contained" color="primary" fullWidth={true}
                                            onClick={openNewProjectDialog} >
                                        New Project
                                    </Button>
                                </Grid>
                                <Grid item xs>
                                    <IconButton
                                        color="primary"
                                        aria-label="project templates"
                                        component="span"
                                        onClick={handleTemplateMenuClick}
                                        size="large">
                                        <ExpandMoreOutlinedIcon fontSize="small" color="primary"/>
                                    </IconButton>
                                    <Menu id="simple-menu" keepMounted
                                            open={Boolean(anchorElTemplate)}
                                            anchorEl={anchorElTemplate}
                                            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                                            transformOrigin={{ vertical: "top", horizontal: "right" }}
                                            onClose={handleTemplateMenuClose}
                                    >
                                    {
                                        project_templates.map((template, index) => 
                                            <MenuItem key={"template-menu="+index} 
                                                        id={"template-menu="+index} 
                                                        onClick={() => {
                                                            //console.log("Menu Click: template=");
                                                            //console.log(template);
                                                            openNewProjectDialogByTemplate(template);
                                                            handleTemplateMenuClose();
                                                        }}
                                            >
                                                {template["name"]}
                                            </MenuItem>
                                        )
                                    }
                                    </Menu>
                                </Grid>
                            </Grid>
                        </Box>
                        <div className={[classes.outerBorder, classes.expandToRemainder].join(" ")} style={{ height: '100%' }}>
                            <ProjectList projects={projects} summaries={summaries} artifacts={artifacts} 
                                            refreshDate={refreshDate} keywordLists={staticData.keywordLists.data}
                                            handleSelectionChange={handleProjectSelectionChange} 
                                            handleEditProject={openEditProjectDialog}
                                            handleCloneProject={openCloneProjectDialog}
                                            handleRefreshProject={handleProjectRefresh}
                                            notifyProjectDeleted={handleProjectDeleted}
                            /> 
                        </div>
                    </Grid>

                    <Grid item xs={9} className={classes.displayArea} style={{ display: "flex", flexDirection: "column" }}>
                        <div className={[classes.outerBorder, classes.expandToRemainder].join(" ")} style={{ height: '100%' }}>
                            <SystemsDetails locations={selectedProject.locations} 
                                            overrides={staticData.locationOverrides.data}
                                            dirtyBit={dirtyBit}
                                            frequencies={selectedProject.frequencies}
                                            talkgroups={selectedProject.talkgroups}
                                            addLocationOverride={handleAddOverride} 
                                            updateLocationOverride={handleUpdateOverride}
                                            deleteLocationOverride={handleDeleteOverride}
                            />
                        </div>
                    </Grid>
                </Grid>

                <ProjectDetailsDialog open={projectDialogOpen} 
                                        handleCancel={handleCancelNewProject} handleSave={handleProjectSave} 
                                        project_id={activeProjectId}
                                        project_name={activeProjectName} 
                                        project_params={activeProjectParams} 
                                        state_list={staticData.usStates.data} 
                                        service_list={staticData.serviceTags.data} 
                                        keywords_list={staticData.keywordLists.data}
                />
            </Container>
        );
    }
}

export default UserDashboard;
