import React from 'react';
import { useState, useEffect, useRef } from 'react';

import MapZoneEditorWindow from '../MapZoneEditorWindow/MapZoneEditorWindow';

import { radiusInMiles, locationFromDictKey } from '../../lib/locationHelpers';

import GoogleMapReact from 'google-map-react';

export const SystemsMapDisplay = ({itemLocationDict, infoWindowToShow,
                                    hideShowSingleMarker, hideShowAllMarkers,
                                    addLocationOverride, updateLocationOverride, deleteLocationOverride}) => {

    const [mapState, setMapState] = useState({
        map: null, 
        maps: null, 
        infoWindow: null,
        loaded: false
    });

    const blue = "blue";//"#0000FF"
    const red = "red";//"#FF0000"
    const green = "green";//"#00FF00"

    const groupColors = {
       "Conventional" : blue,
       "Agencies" : green,
       "Trunked" : red
    };

    // renderItems holds the actual Google Maps objects that are drawn
    const [renderItems, setRenderItems] = useState({});
    const renderItemsRef = useRef();

    const [editorId, setEditorId] = useState(null);
    const [editorItem, setEditorItem] = useState(null);
    const [editorOriginal, setEditorOriginal] = useState(null);

    //
    // CRITICAL:  https://stackoverflow.com/questions/57847594/react-hooks-accessing-up-to-date-state-from-within-a-callback
    // make renderItemsRef always have the current value of the state variable.
    // your "fixed" callbacks can refer to this object whenever
    // they need the current value.  Note: the callbacks will not
    // be reactive - they will not re-run the instant state changes,
    // but they *will* see the current value whenever they do run
    //
    renderItemsRef.current = renderItems;

    //
    // When the location dict has been updated, re-render
    // (Make sure we have the map object)
    //
    useEffect(() => {
        //console.log("useEffect():  render map");
        //console.log("infoWindowToShow="+infoWindowToShow);
        if(mapState.loaded && itemLocationDict != null) {
            renderMapItems();
        }
    }, [mapState, itemLocationDict, infoWindowToShow]);


    // Callback used by the GoogleMapReact component
    const apiIsLoaded = (map, maps) => {
        //console.log("apiIsLoaded");

        let infoWindow = new maps.InfoWindow();
        let state = {
            map: map, 
            maps: maps,
            infoWindow: infoWindow,
            loaded: true
        };

        maps.event.addListener(map, "click", function(event) {
           infoWindow.close();
        });

        setMapState(state);
    };

    //
    // Map Rendering Helpers
    //
    const safeTags = (str) => {
        return str.replace(/&/g,'&amp;').replace(/</g,'&lt;').replace(/>/g,'&gt;');
    }

    const createInfoWindowContent = (items, center, radius) => {
        var content = document.createElement("div");

        let firstItem = items[0];
        let override = firstItem.locationOverride ? firstItem.locationOverride.override : null;

        //
        // Top Line Links
        //
        var hide_zone = document.createElement("a")
        hide_zone.innerText = "[hide zone]"
        hide_zone.href = "#"
        hide_zone.onclick = function() { 
            mapState.infoWindow.close();

            for(let i in items) {
                hideShowSingleMarker(false, items[i]);
            }
        }

        var edit_global = document.createElement("a")
        edit_global.innerText = (override && !override.matchId) ? "[edit global]" : "[create global]";
        edit_global.href = "#"
        edit_global.onclick = function() { 
            mapState.infoWindow.close();

            hideShowAllMarkers(false);
            for(let i in items) {
                hideShowSingleMarker(true, items[i]);
            }

            // Only pass the current override if the id is null (global mode)
            let overrideToPass = (override && override.matchId == null) ? override : null;
            setEditorOriginal(overrideToPass);
            setEditorId(null);
            setEditorItem(firstItem);
        }

        var top_line = document.createElement("div");
        top_line.style.cssText = "display: flex; justify-content: space-between;";
        top_line.appendChild(hide_zone);
        top_line.appendChild(edit_global);

        // Add to content
        content.appendChild(top_line);
        // Line break
        content.appendChild(document.createElement("br"));

        //
        // List of item names & edit links
        //
        for(let i in items) {
            let item = items[i];

            // Only pass the current override if the id matches (specific mode)
            let overrideToPass = (override && override.matchId === item.id) ? override : null;

            var name = document.createElement("b");
            name.innerHTML = safeTags(item.name);

            var edit = document.createElement("a")
            edit.innerText = overrideToPass ? "[edit]" : "[create new]";
            edit.href = "#"
            edit.onclick = function() { 
                mapState.infoWindow.close();

                hideShowAllMarkers(false);
                for(let idx in items) {
                    hideShowSingleMarker(true, items[idx]);
                }

                setEditorOriginal(overrideToPass);
                setEditorId(item.id);
                setEditorItem(item);
            }

            var line = document.createElement("div");
            line.style.cssText = "display: flex; justify-content: space-between;";
            line.appendChild(name);
            line.appendChild(edit);

            // Add to content
            content.appendChild(line);
        }
        // Line break
        content.appendChild(document.createElement("br"));

        //
        // Summary details
        //
        var label = document.createElement("div")
        label.innerHTML = "lat=" + center.lat.toFixed(2)+", lng="+center.lng.toFixed(2)+", radius="+radiusInMiles(radius).toFixed(2);
            
        if(override) {
            label.innerHTML += "</br> </br> Location Override";
            if(override.matchId) {
                label.innerHTML += " ["+override.matchId+"]";
            } else {
                label.innerHTML += " [GLOBAL]";
            }

            // Is this still a "draft"?
            if(!firstItem.locationOverride.isApplied) {
                label.innerHTML += " &mdash; ** DRAFT **";
            }
        }

        // Add to content
        content.appendChild(label);

        return content;
    }

    const createSingleMarker = (items, color, showInfoWindow) => {
        //console.log("createSingleMarker:");
        //console.log(items);
        
        let firstItem = items[0];
        let locationOverride = firstItem.locationOverride;
        //console.log(locationOverride);

        let fillColor = color;
        if(locationOverride) {
            fillColor = (locationOverride.isApplied ? "orange" : "fuchsia");
        }

        let markerCenter = firstItem.location.center;
        let markerRadius = firstItem.location.radius;

        var circle = new mapState.maps.Circle({
            map: mapState.map,
            strokeColor: color, strokeOpacity: 0.8, strokeWeight: 2,
            fillColor: fillColor, fillOpacity: 0.35,
            center: markerCenter, radius: markerRadius
        });

        var marker = new mapState.maps.Marker({
            map: mapState.map,
            position: markerCenter,
        });

        var render_item = {circle: circle, marker:marker};
        var content = createInfoWindowContent(items, markerCenter, markerRadius);

        const showInfoWindowCallback = () => {
            mapState.infoWindow.setContent(content);
            mapState.infoWindow.open(mapState.map, marker);
        }
        
        mapState.maps.event.addListener(marker, 'click', showInfoWindowCallback);

        if(showInfoWindow) {
            showInfoWindowCallback();
        }

        return render_item;
    }

    const createMarkers = (location_dict, color, bounds) => {
        //console.log("createMarkers(): color="+color+"; location_dict=");
        //console.log(location_dict);

        let render_items = []

        for(let key in location_dict) {
            let location = locationFromDictKey(key);
            let items = location_dict[key];
            if(items.length > 0) {
                // The checkbox tree toggles visibility.
                // Collect all the items that should be shown
                let visibleItems = [];
                for(let i in items) {
                    let visible = items[i].visible;
                    if(visible) {
                        // Push the data - those are the raw items from the location prop
                        visibleItems.push(items[i].data);
                    }
                }
                //console.log("visibleItems=");
                //console.log(visibleItems)

                if(visibleItems.length > 0) {
                    const showInfoWindow = (key === infoWindowToShow);
                    const marker = createSingleMarker(visibleItems, color, showInfoWindow);
                    if(marker) {
                        bounds.extend(location.center);
                        bounds.union(marker.circle.getBounds());
    
                        render_items.push(marker);
                    }
                }
            }
        } // end for keys

        return render_items;
    }

    const renderMapItems = () => {
        //console.log("+++ renderMapItems");

        clearAllMarkers(renderItemsRef.current);

        let bounds = new mapState.maps.LatLngBounds();

        let render_list = [];
        for (let group_name in itemLocationDict) {
            let color = groupColors[group_name];
            let group_dict = itemLocationDict[group_name];
            let markers = createMarkers(group_dict, color, bounds);
            //console.log(group_name+": markers.length="+markers.length);
            render_list.push(...markers);
        }

        //console.log("renderMapItems(): render_list=");
        //console.log(render_list);
        setRenderItems(render_list);

        // Only update the bounds if we have something to render
        if(render_list.length > 0) {
            mapState.map.fitBounds(bounds);
        }

        //console.log("--- renderMapItems");
    }

    const clearAllMarkers = (render_items) => {
        //console.log("+++ clearAllMarkers");
        //console.log("renderItems=");
        //console.log(render_items);

        for(let idx in render_items) {
            let item = render_items[idx];
            
            item.marker.setMap(null);
            delete item.marker;

            item.circle.setMap(null);
            delete item.circle;
        }
        //console.log("--- clearAllMarkers")
    }

    //
    // Zone Editor functions (save, delete, cancel/close)
    //
    const handleZoneEditorSaveGlobal = (item, center, radius, original) => {
        console.log("handleZoneEditorSaveGlobal");

        const id = null
        const match = {
            "lat": (original ? original.matchLocation.lat : item.location.center.lat),
            "lng": (original ? original.matchLocation.lng : item.location.center.lng),
            "radius": (original ? original.matchLocation.radius : item.location.radius),
        };
        const replace = {
            "lat": center.lat,
            "lng": center.lng,
            "radius": radius,
        };

        handleZoneEditorSaveOrUpdate(original, id, match, replace);
    }

    const handleZoneEditorSaveSpecific = (id, center, radius, original) => {
        console.log("handleZoneEditorSaveSpecific");

        const match = null;
        const replace = {
            "lat": center.lat,
            "lng": center.lng,
            "radius": radius,
        };

        handleZoneEditorSaveOrUpdate(original, id, match, replace);
    }

    const handleZoneEditorSaveOrUpdate = (original, id, match, replace) => {
        console.log("handleZoneEditorSaveOrUpdate");
        console.log("values:");
        console.log(original);
        console.log(id);
        console.log(match);
        console.log(replace);

        // Clear the edit window
        setEditorId(null);
        setEditorItem(null);
        setEditorOriginal(null);

        if(original) {
            // Update
            console.log("UPDATE");
            //console.log(original);
            updateLocationOverride(original.locationId, id, match, replace);
        } else {
            // Create
            console.log("CREATE");
            addLocationOverride(id, match, replace);
        }

        // Redraw the zones
        hideShowAllMarkers(true);
    }

    const handleZoneEditorDelete = (item, original) => {
        console.log("handleZoneEditorDelete");
        console.log(original);

        // Clear the edit window
        setEditorId(null);
        setEditorItem(null);
        setEditorOriginal(null);

        // Delete from server
        deleteLocationOverride(original.locationId);

        // Redraw the zones
        hideShowAllMarkers(true);
    }

    const handleZoneEditorCancel = () => {
        console.log("handleZoneEditorCancel");

        // Clear the edit window
        setEditorId(null);
        setEditorItem(null);
        setEditorOriginal(null);

        // Redraw the zones
        hideShowAllMarkers(true);
    }

    return (
        <div style={{ width: '100%', height: "100%"}}>
            <GoogleMapReact 
                bootstrapURLKeys={{ key: 'AIzaSyDlDUd0SxIeMe1yNtxQwZrCfN_yRr5J-1U' }}
                defaultCenter={{ lat: 39.8283, lng: -98.5795 }}
                defaultZoom={4}
                yesIWantToUseGoogleMapApiInternals={true}
                onGoogleApiLoaded={({ map, maps }) => apiIsLoaded(map, maps)}
            >
            </GoogleMapReact>

            <MapZoneEditorWindow map={mapState.map} maps={mapState.maps}
                                    visible={editorItem != null}
                                    id={editorId} item={editorItem} original={editorOriginal} 
                                    handleCancel={handleZoneEditorCancel} 
                                    handleDelete={handleZoneEditorDelete}
                                    handleSaveGlobal={handleZoneEditorSaveGlobal} 
                                    handleSaveIndividual={handleZoneEditorSaveSpecific} />
        </div>
    )
}

export default SystemsMapDisplay;
