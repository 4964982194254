import React from 'react';
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { SimpleTreeView, TreeItem, useTreeItemState } from '@mui/x-tree-view';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import NoEncryptionGmailerrorredOutlinedIcon from '@mui/icons-material/NoEncryptionGmailerrorredOutlined';

import clsx from 'clsx';
import Typography from '@mui/material/Typography';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
    noWrap: {
        whiteSpace: 'nowrap',
        marginTop: "0px",
        marginLeft: "1px",
        paddingLeft: "0px",
    }
}));


const CustomContent = React.forwardRef(function CustomContent(props, ref) {
    const {
      classes,
      className,
      label,
      itemId,
      icon: iconProp,
      expansionIcon,
      displayIcon,
      checked,
      onCheckboxToggle,
      onExpansionToggle,
      onSelect,
      item,
    } = props;

    const displayCheckbox = (item.nodeType !== "TALKGROUP" ? true : false);
  
    const {
      disabled,
      expanded,
      focused,
      handleExpansion,
      handleSelection,
      preventSelection,
    } = useTreeItemState(itemId);
  
    const icon = iconProp || expansionIcon || displayIcon;
  
    const handleMouseDown = (event) => {
      preventSelection(event);
    };
  
    const handleExpansionClick = (event, id) => {
      handleExpansion(event);
      if(onExpansionToggle) {
        onExpansionToggle(id, expanded);
      }
    };
  
    const handleSelectionClick = (event, id) => {
      if(displayCheckbox) {
        handleExpansionClick(event, id);

        // Pass item.data (even if null) through the callback
        handleSelection(event);
        if(onSelect) {
          onSelect(id, item.data);
        }
      }
    };
  
    return (
      // eslint-disable-next-line jsx-a11y/no-static-element-interactions
      <div
        className={clsx(className, classes.root, {
          [classes.expanded]: expanded,
          [classes.focused]: focused,
          [classes.disabled]: disabled,
        })}
        onMouseDown={handleMouseDown}
        ref={ref}
      >
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
        <div onClick={ (e) => { handleExpansionClick(e, itemId); } } className={classes.iconContainer}>
          {icon}
        </div>
        <Checkbox checked={checked} 
                style={{display: (displayCheckbox ? "inline" : "none"), margin: "0px", padding: "0px"}} 
                onChange={ (e) => { onCheckboxToggle(e, item); } } 
        />
        <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start" wrap="nowrap" >          
          <LockOutlinedIcon color="secondary" fontSize="small"
                            style={{display: (!displayCheckbox && item.encrypted) ? "inline" : "none"}}
          />
          <NoEncryptionGmailerrorredOutlinedIcon color="primary" fontSize="small"
                                                  style={{display: (!displayCheckbox && !item.encrypted) ? "inline" : "none"}}/>

          <Grid container direction="column" justifyContent="flex-start" alignItems="flex-start" wrap="nowrap" >  
            <Typography display="inline"
              onClick={ (e) => { handleSelectionClick(e, itemId); } }
              component="div"
              className={classes.label}
              style={{marginTop: "-5px"}} 
            >
              {label}
            </Typography>
            <Typography variant="caption" 
                        display={!displayCheckbox ? "inline" : "none"}
                        style={{marginTop: "-5px"}} 
            >
              &nbsp;[{item.serviceName}]
              </Typography>
          </Grid>
        </Grid>
      </div>
    );
  });
  
  CustomContent.propTypes = {
    /**
     * Override or extend the styles applied to the component.
     */
    classes: PropTypes.object.isRequired,
    /**
     * className applied to the root element.
     */
    className: PropTypes.string,
    /**
     * The icon to display next to the tree node's label. Either a parent or end icon.
     */
    displayIcon: PropTypes.node,
    /**
     * The icon to display next to the tree node's label. Either an expansion or collapse icon.
     */
    expansionIcon: PropTypes.node,
    /**
     * The icon to display next to the tree node's label.
     */
    icon: PropTypes.node,
    /**
     * The tree node label.
     */
    label: PropTypes.node,
    /**
     * The id of the node.
     */
    itemId: PropTypes.string.isRequired,
    /**
     * Checkbox state.
     */
    checked: PropTypes.bool,
    /**
     * Checkbox onChange callback.
     */
    onCheckboxToggle: PropTypes.func.isRequired,
    /**
     * Tree node open/close callback.
     */
    onExpansionToggle: PropTypes.func.isRequired,
    /**
     * Tree node selected callback.
     */
    onSelect: PropTypes.func.isRequired,

    item: PropTypes.object.isRequired
  };
  
  function CustomTreeItem(props) {
    let props_trimmed = {...props};
    delete props_trimmed.onCheckboxToggle
    delete props_trimmed.onExpansionToggle
    return <TreeItem ContentComponent={CustomContent} ContentProps={props} {...props_trimmed} />;
  }

export const SystemsTreeView = ({name, items, onToggle, onSelect}) => {
    const classes = useStyles();

    const [expansionList, setExpansionList] = useState([]);
    const [fullIdList, setFullIdList] = useState([]);

    useEffect(() => {
        if(items) {
          //console.log("SystemsTreeView:");
          //console.log(items);
          //console.log("---");
          let top_level = getTopLevelIdList(items);
          let all_ids = getDeptIdList(items); //getFullIdList(items);
          //console.log("top_level=");
          //console.log(top_level);

          // Do we have the same full set as previously?
          // If so, don't change the expansion state.
          // If they are different, then we can make changes.
          if(!arraysEqual(all_ids, fullIdList)) {
            setExpansionList(top_level);
            setFullIdList(all_ids);
          }
        } else {
          setExpansionList([]);
        }
    }, [items]);

    const arraysEqual = (a, b) => {
      return a.length === b.length && a.every((element, index) => element === b[index]);
    }

    const handleExpansion = (id, expanded) => {
      //console.log("handleExpansion():  id="+id+"; expanded="+expanded);
      let list = [...expansionList];
      const index = list.indexOf(id);
      // Does this ID exist in the expansion list?
      if (index > -1) { 
        // Yes.  So remove it.
        list.splice(index, 1); // 2nd parameter means remove one item only
      } else {
        // Nope.  Add it.
        list.push(id);
      }
      setExpansionList(list);
    }

    const handleSelection = (id, item) => {
      //console.log("handleSelection(): id="+id);
      //console.log("item=");
      //console.log(item);
      if(onSelect) {
        onSelect(id,item);
      }
    }

    const renderTree = (nodes) => {
        return nodes.map((node) => (
            <CustomTreeItem className={classes.noWrap}
                            key={node.treeId} itemId={node.treeId.toString()} 
                            label={node.name} checked={node.visible} 
                            onCheckboxToggle={handleChange}
                            onExpansionToggle={handleExpansion}
                            onSelect={handleSelection}
                            item={node} >
                { (node.children && node.children.length > 0) ? renderTree(node.children) : null }
            </CustomTreeItem>
        ));
    }

    const getTopLevelIdList = (items) => {
        let ids = [];
        
        items.forEach(function (item, index) {
            ids.push(item.treeId.toString());
        });

        return ids;
    }

    const getDeptIdList = (items) => {
        let ids = [];
        
        items.forEach(function (item, index) {
            if(item.nodeType !== "SITE") {
                ids.push(item.treeId.toString());
                if(item.children.length > 0) {
                    let childrenIds = getDeptIdList(item.children);
                    ids.push(...childrenIds);
                }
            }
        });

        return ids;
    }

    const getFullIdList = (items) => {
        let ids = [];
        
        items.forEach(function (item, index) {
            ids.push(item.treeId.toString());
            if(item.children.length > 0) {
                let childrenIds = getFullIdList(item.children);
                ids.push(...childrenIds);
            }
        });

        return ids;
    }

    const handleChange = (e, item) => {
        //console.log("checkbox ["+item.name+"] - handleChange(): "+e.target.checked);
        if(onToggle) {
            onToggle(e.target.checked, item)
        }
    };

    const expandAll = () => {
      //console.log("expandAll():  fullIdList=");
      //console.log(fullIdList);
      setExpansionList(fullIdList);
    }

    const collapseAll = () => {
      setExpansionList([]);
    }

    if(items && items.length > 0) {

      return (
          <div>
            <center>
              <Button onClick={expandAll} >Expand All</Button>
              &nbsp; | &nbsp; 
              <Button onClick={collapseAll} >Collapse All</Button>
            </center>
            <SimpleTreeView
                aria-label="rich object"
                slots={{ collapseIcon: ExpandMoreIcon, expandIcon: ChevronRightIcon }}
                expandedItems={expansionList}
                sx={{ height: '100%', flexGrow: 1, maxWidth: 400, overflowY: 'auto' }}
            >
                {renderTree(items)}
            </SimpleTreeView>
          </div>
      );
    } else {
        return (
            <div/>
        );
    }
        
}

export default SystemsTreeView;
