import React from 'react';

import makeStyles from '@mui/styles/makeStyles';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@mui/material';

const useStyles = makeStyles((theme) => ({

}));

export const ConfirmationDialog = ({open, handleConfirm, handleCancel, title, message, actionName}) => {
    const classes = useStyles();
    return(
        <div className={classes.root}>
            <Dialog open={open}  onClose={handleCancel} maxWidth="sm" fullWidth={true}>
                <DialogTitle>{title}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {message}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancel} color="primary"  variant="contained">
                        Cancel
                    </Button>
                    <Button color="secondary" variant="contained" autoFocus
                            onClick={() => {
                                //console.log("ConfirmationDialog.onClick()");
                                //console.log("handleConfirm=");
                                //console.log(handleConfirm);
                                if(handleConfirm) {
                                    //console.log("ConfirmationDialog triggering callback");
                                    handleConfirm();
                                }
                            }} 
                    >
                        {actionName}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default ConfirmationDialog