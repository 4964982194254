import React from 'react';
import { useState, useEffect, useRef } from 'react';

import SystemsTreeView from '../SystemsTreeView/SystemsTreeView';
import SystemsMapDisplay from '../SystemsMapDisplay/SystemsMapDisplay';
import SystemsMapDisplayOptions from '../SystemsMapDisplayOptions/SystemsMapDisplayOptions';
import FrequencyDetailsDrawer from '../FrequencyDetailsDrawer/FrequencyDetailsDrawer';

import { radiusInMiles, dictKeyFromLocation, locationObjectFromCenterRadius } from '../../lib/locationHelpers';

import makeStyles from '@mui/styles/makeStyles';
import { Container, TextField } from '@mui/material';

const useStyles = makeStyles((theme) => ({
    displayArea: {
        display: "flex",
        flexFlow: "column",
        height: "100%",
    },
    rightColumn: {
        display: "flex",
        flexFlow: "column",
        height: "100%",
        margin: '-1px',
        borderWidth: '1px',
        borderStyle: "solid", 
        borderColor: theme.palette.primary.main
    },
    fixedSize: {
        /* sized to content */
        flexGrow: 0,
        flexShrink: 1,
        flexBasis: "auto",
    },
    expandToRemainder: {
        /* fill remaining space */
        flexGrow: 1,
        flexShrink: 1,
        flexBasis: "auto",

        // critical to make sure scrolling works:  
        //  https://stackoverflow.com/questions/14962468/how-can-i-combine-flexbox-and-vertical-scroll-in-a-full-height-app
        height: "0px", 

        overflow: "auto",
    },
  }));

const NODE_TYPE_GROUP = "GROUP";
const NODE_TYPE_SYSTEM = "SYSTEM";
const NODE_TYPE_DEPT = "DEPARTMENT";
const NODE_TYPE_SITE = "SITE";
const NODE_TYPE_TG = "TALKGROUP";

export const SystemsDetails = ({locations, overrides, dirtyBit, frequencies, talkgroups,
                                addLocationOverride, updateLocationOverride, deleteLocationOverride}) => {
    const classes = useStyles();

    // The primary state is a pre-processed representation of all locations & overrides
    // Other structures are derived & filtered from this based on UI control selections
    const [itemPrimaryStateDict, setItemPrimaryStateDict] = useState({});

    // itemLocationDict holds the derived content for rendering the map display
    const [itemLocationDict, setItemLocationDict] = useState({});

    // checkboxTree holds the content used to render the CheckboxTree control
    const [checkboxTree, setCheckboxTree] = useState(null);
    const checkboxTreeRef = useRef();

    // Track which item in the tree has been selected
    const [selectedLocation, setSelectedLocation] = useState(null);

    const [minRadius, setMinRadius] = useState(0);

    const [showOverride, setShowOverride] = useState("show");

    const [showTrunkedSys, setShowTrunkedSys] = useState(true);
    const [showTrunkedSites, setShowTrunkedSites] = useState(true);
    const [showConventional, setShowConventional] = useState(true);

    const [serviceChoices, setServiceChoices] = useState([]);
    const [serviceSelections, setServiceSelections] = useState([]);

    const [filterText, setFilterText] = useState("");

    //
    // CRITICAL:  https://stackoverflow.com/questions/57847594/react-hooks-accessing-up-to-date-state-from-within-a-callback
    // make checkboxTreeRef always have the current value of the state variable.
    // your "fixed" callbacks can refer to this object whenever
    // they need the current value.  Note: the callbacks will not
    // be reactive - they will not re-run the instant state changes,
    // but they *will* see the current value whenever they do run
    //
    checkboxTreeRef.current = checkboxTree;

    //
    // Pre-compute data structures that are easier to render and filter
    //
    useEffect(() => {
        if(locations) {
            //console.log("useEffect():  locations & overrides");
            let state_dict = buildPrimaryStateDict(locations, talkgroups);
            let choices = getServiceTagOptions(state_dict);
            //console.log("state_dict=");
            //console.log(state_dict);

            setItemPrimaryStateDict(state_dict);
            setServiceChoices(choices);
            setServiceSelections(choices); // Initally have all services selected
        } else {
            setItemPrimaryStateDict({});
            setServiceChoices([]);
            setServiceSelections([]);

            setItemLocationDict({});
            setCheckboxTree(null);
        }
    }, [locations, overrides, dirtyBit, talkgroups]);

    useEffect(() => {
        // Map specific group names back to the visiblity settings
        let visibilty_by_group_name = {
            "Trunked": showTrunkedSys,
            "<Sites>": showTrunkedSites,
            "Conventional": showConventional,
        };

        let loc_dict = buildLocationDict(itemPrimaryStateDict, visibilty_by_group_name, serviceSelections);
        let checkboxes = buildCheckboxTree(itemPrimaryStateDict, visibilty_by_group_name, serviceSelections);
        
        setItemLocationDict(loc_dict);
        setCheckboxTree(checkboxes);
    }, [itemPrimaryStateDict, serviceSelections, 
        showTrunkedSys, showTrunkedSites, showConventional, 
        showOverride, minRadius, filterText]);

    //
    // The hide/show functions will update the primary state dictionary through references within itemLocationDict.
    // These are called from the map's info window UI.  
    // This function will update the state here, which will flow down into the SystemsMapDisplay.
    //
    const hideShowSingleMarker = (show, item) => {
        //console.log("hideShowSingleMarker: show="+show);
        //console.log(item);

        // Need to find the item in the location item dictionary so that it can be hidden from rendering
        let key = dictKeyFromLocation(item.location);
        let dict_list = null;
        for(let group_name in itemLocationDict) {
            let sub_dict = itemLocationDict[group_name];
            if(key in sub_dict) {
                // It's possible to match the key in each group.  
                //  e.g. same center & radius, for both trunked & conventional.
                dict_list = sub_dict[key];
                for(let idx in dict_list) {
                    let wrapper_item = dict_list[idx];
                    let render_item = wrapper_item.data;
                    //console.log("render_item=");
                    //console.log(render_item);
                    
                    // We toggle visibility on the reference to the object in the item state dictionary
                    // When this function completes, we will update the state and other structures will be derived again.
                    if(render_item.id === item.id) {
                        let state_obj = wrapper_item.stateRef;
                        state_obj.visible = show;
                        //console.log("toggling wrapper_item");
                        //console.log(wrapper_item);
                    }
                }
            }
        }

        //
        // Update state so that child controls re-render.
        // We rebuild both the location dictionary and checkbox tree.
        // When rebuiling those, we must pass 'null' for the visibility map parameter
        //    to avoid overriding the visibility settings that have been configured manually.
        //
        let loc_dict = buildLocationDict(itemPrimaryStateDict, null, serviceSelections);
        setItemLocationDict(loc_dict);
        let checkboxes = buildCheckboxTree(itemPrimaryStateDict, null, serviceSelections)
        setCheckboxTree(checkboxes);
    }

    const hideShowAllMarkers = (visible) => {
        // Hide all
        for(let group_name in itemLocationDict) {
            let sub_dict = itemLocationDict[group_name];
            for(let key in sub_dict) {
                let dict_list = sub_dict[key];
                for(let idx in dict_list) {
                    let wrapper_item = dict_list[idx];
                    // We toggle visibility on the reference to the object in the item state dictionary
                    // When this function completes, we will update the state and other structures will be derived again.
                    let state_obj = wrapper_item.stateRef;
                    state_obj.visible = visible;
                }
            }
        }

        //
        // Update state so that child controls re-render.
        // We rebuild both the location dictionary and checkbox tree.
        // When rebuiling those, we must pass 'null' for the visibility map parameter
        //    to avoid overriding the visibility settings that have been configured manually.
        //
        let loc_dict = buildLocationDict(itemPrimaryStateDict, null, serviceSelections);
        setItemLocationDict(loc_dict);
        let checkboxes = buildCheckboxTree(itemPrimaryStateDict, null, serviceSelections)
        setCheckboxTree(checkboxes);
    }

    // 
    // The toggle functions will update state in response to clicks on the checkbox tree
    //
    const toggleTree = (show, tree) => {
        tree.visible = show;

        // Need to be sure we are updating the primary state dict and deriving other structures from that
        let stateRef = tree.stateRef;
        stateRef.visible = show;

        if(tree.children.length > 0) {
            for(let idx in tree.children) {
                let child = tree.children[idx];
                // Recursively toggle visibility
                toggleTree(show, child);
            }
        }
    }

    const toggleCheckboxItem = (show, item) => {
        //console.log("toggleCheckboxItem["+item.name+"]: show="+show);
        //console.log(item);

        // Special cases for the top level.
        // If those are being toggled, then update the corresponding UI controls.
        if(item.name === "Trunked") {
            setShowTrunkedSys(show);
            setShowTrunkedSites(show);
        } else if (item.name === "Conventional") {
            setShowConventional(show);
        } else {
            // If not handling the top level, then re-derive all relevant children.
            toggleTree(show, item);
        }

        //
        // Update state so that child controls re-render.
        // We rebuild the location dictionary and pass 'null' for the visibility map parameter
        //    to avoid overriding the visibility settings that have been configured manually.
        //
        let loc_dict = buildLocationDict(itemPrimaryStateDict, null, serviceSelections);
        setItemLocationDict(loc_dict);
        let checkboxes = [...checkboxTree];
        setCheckboxTree(checkboxes);
    }

    const handleTreeSelection = (id, item) => {
        if(item) {
            let key = dictKeyFromLocation(item.location)
            //console.log("handleTreeSelection(): key="+key);
            setSelectedLocation(key);

        } else {
            //console.log("handleTreeSelection(): item is null");
            setSelectedLocation(null);
        }
    }

    const sliderOnChange = (e, value) => {
        setMinRadius(value);
    }

    const handleSetServiceSelectionValues = (values) => {
        console.log("handleSetServiceSelectionValues(): values=");
        console.log(values);
        setServiceSelections(values); 
    }

    const findBestOverride = (itemId, overrideId, location) => {
        //console.log("findBestOverride");
        //console.log("itemId="+itemId);
        //console.log("overrideId="+overrideId);
        //console.log("location=");
        //console.log(location);
        //console.log("------------");

        //
        // It's possible that an item has been processed with one override,
        //    and then a new "draft" has been created that is more specific.
        // We look for those to prioritize first.
        //
        let tmpOverride = findItemOverrideByItemId(itemId);
        if(!tmpOverride) {
            if(overrideId) {
                tmpOverride = findItemOverrideByLocationId(overrideId);
            } else if (location) {
                // Items with new overrides that haven't been applied don't have an overrideId yet
                tmpOverride = findItemOverrideByLocation(location);
            }
        } /*else  {
            console.log("found match on item.id");
            console.log(tmpOverride);
        }
        */
        return tmpOverride;
    }

    const findItemOverrideByLocationId = (overrideId) => {
        //console.log("findItemOverrideByLocationId()");
        if(overrideId) {
            //console.log("findItemOverrideByLocationId() - item has an overrideId");
            //console.log(item);
            //console.log(overrides);

            if(overrideId in overrides) {
                // Found a match!
                let override = overrides[overrideId];
                return override;
            }
        }
        //console.log("findItemOverrideByLocationId() - NO MATCH");
        return null;
    }

    const findItemOverrideByItemId = (id) => {
        //console.log("findItemOverrideByItemId() - id="+id);
        if(id) {
            if(id in overrides) {
                // Found a match!
                //console.log("findItemOverrideByItemId() - found a match for the ID!");
                let override = overrides[id];
                return override;
            }
        }
        return null;
    }

    const findItemOverrideByLocation = (location) => {
        let key = dictKeyFromLocation(location);
        if(key in overrides) {
            return overrides[key];
        }
        return null;
    }

    const findItemLocationByParam = (overrideId, location, paramToCompare) => {
        //console.log("findItemLocationByParam()");
        let override = findItemOverrideByLocationId(overrideId);
        if(override) {
            //console.log("findItemLocationByParam("+paramToCompare+") - item has an override");
            let compare = override[paramToCompare];

            //console.log(override);
            //console.log(compare);
            
            if(location.center.lat === compare.lat && location.center.lng === compare.lng && location.radius === compare.radius) {
                //console.log("findItemLocationByParam("+paramToCompare+") - matched on parameter!");
                return override;
            }
        }
        //console.log("findItemLocationByParam() - NO MATCH");
        return null;
    }

    const findItemLocationApplied = (overrideId, location) => {
        // This returns a result when the override value has been used to adjust the location zone
        return findItemLocationByParam(overrideId, location, "overrideLocation")
    }

    //
    // Recursive function to generate a tree structure for location data
    //
    // This data structure is computed when location data changes or is filtered, and then it is used to determine
    //   what should be displayed based on presence/absence of location overrides, size of locations, and name text.
    //
    // Tree is an array of group names ('Trunked', 'Agencies', 'Conventional') where each array item is an object.
    // Those objects each have an array of children (the systems) and other properties to simplify searching & rendering
    //
    // Data objects look like this:
    //    obj.children[]
    //    obj.name:         Display name for the node.  Could be the group type, system, department, 'Sites'/'Departments', site name, etc.
    //    obj.searchText:   String that aggregates searchable text from children)
    //    obj.maxRadius:    Largest radius from all children
    //    obj.hasOverride:  True if *any* of the children have an override.  The override object will be found in the 'data' property.
    //    obj.visible
    //    obj.data:         For leaf nodes, populated with a location object.  otherwise null. )
    //
    const aggregateItemsForStateDict = (name, items, talkgroups, nodeTypeName, nextId) => {
        // Track properties that depend on child state and roll them up
        let data = null;
        let searchText = "";
        let maxRadius = 0;
        let hasOverride = false;
        let children = [];

        // Does 'items' represent a dictionary? Or array?
        if(items.constructor === Object) {

            // Dictionary.
            searchText += name;
            Object.keys(items).forEach( (key) => {
                // Tag each item with the type of node
                // That could be useful in how we display the details
                let childNodeType = nodeTypeName;
                if(nodeTypeName === NODE_TYPE_GROUP) {
                    childNodeType = NODE_TYPE_SYSTEM;
                } else if(nodeTypeName === NODE_TYPE_SYSTEM) {
                    if(key === "Departments") {
                        childNodeType = NODE_TYPE_DEPT;
                    } else if(key === "<Sites>") {
                        childNodeType = NODE_TYPE_SITE;
                    }
                }

                let tgs = talkgroups;
                if(key !== "Departments") {
                    tgs = (talkgroups ? talkgroups[key] : null);
                }

                let child = aggregateItemsForStateDict(key, items[key], tgs, childNodeType, nextId);
                // Increment the next ID past whatever this child has.
                // We are doing a depth-first search, so leaves will be assigned IDs first.
                nextId = child.treeId + 1

                if(child.maxRadius > maxRadius) {
                    maxRadius = child.maxRadius;
                }
                if(child.hasOverride) {
                    hasOverride = true;
                }
                children.push(child);

                // Aggregate the text from all children for easy searching
                searchText += child.searchText;
            });
        } else {
            data = items[0];

            // Array
            items.forEach( (item) => {
                searchText += item.name;

                let locationOverride = null;

                // Make sure the overrideId matches the "best" that we found
                let location = locationObjectFromCenterRadius(item.center, item.radius);
                let tmpOverride = findBestOverride(item.id, item.overrideId, location);

                hasOverride = (tmpOverride != null);

                // Now compute the override data based on the object we found
                if(tmpOverride) {
                    let override = tmpOverride;

                    let compareLocation = {center: item.center, radius: item.radius};
                    let applied = findItemLocationApplied(tmpOverride.locationId, compareLocation);

                    let isApplied = (applied != null);

                    let overrideCenter = {"lat": override.overrideLocation.lat, "lng": override.overrideLocation.lng};
                    let overrideRadius = override.overrideLocation.radius;

                    locationOverride = {
                        override: override,
                        isApplied: isApplied,
                        center: overrideCenter,
                        radius: overrideRadius
                    }
                }

                // Now figure out what the final coordinate values are
                let radius = location.radius;
                if(locationOverride) {
                    location = locationObjectFromCenterRadius(locationOverride.center, locationOverride.radius);
                    radius = locationOverride.radius;
                }

                // Store "solved" override info on the data object
                data.location = location;
                data.locationOverride = locationOverride;

                // And aggregate the radius using the override
                if(radius > maxRadius) {
                    maxRadius = radius;
                }

                // If we have talkgroups, add those as childrew
                if(talkgroups) {
                    nodeTypeName = NODE_TYPE_DEPT;
                    Object.keys(talkgroups).forEach( (name) => {
                        searchText += name;
    
                        let tg = talkgroups[name];
                        tg.name = name;
                        tg.nodeType = NODE_TYPE_TG;
                        tg.children = [];
                        tg.searchText = name;
    
                        // Use the parent location data
                        tg.maxRadius = radius;
                        tg.location = location;
                        tg.locationOverride = locationOverride;
                        tg.hasOverride = hasOverride;

                        // Increment the next ID for each of these
                        tg.treeId = nextId;
                        nextId += 1;
    
                        children.push(tg);
                    });
                } 
            });
        }

        let aggregation = {
            treeId: nextId,
            visible: true, // this will get adjusted when building the location/checkbox data structures
            name: name,
            searchText: searchText,
            data: data,
            maxRadius: maxRadius,
            hasOverride: hasOverride,
            children: children,
            nodeType: nodeTypeName
        }

        return aggregation;
    }

    const buildPrimaryStateDict = (raw_location_dict, talkgroups) => {
        //console.log("buildPrimaryStateDict(): raw_location_dict=");
        //console.log(raw_location_dict);

        // Need to assign unique node IDs to each item for use with TreeView
        let nextId = 1;

        let tree = [];
        for (let group_name in raw_location_dict) {
            let group = raw_location_dict[group_name]
            // Process only the groups that are not empty... 
            // (this avoids having empty top-level checkboxes in the tree)
            let all_keys = Object.keys(group);
            if(all_keys.length > 0){
                //console.log("group="+group_name+", visible="+visible);
                let subtree = aggregateItemsForStateDict(group_name, group, talkgroups, NODE_TYPE_GROUP, nextId);
                tree.push(subtree);
                
                // Increment past the last child's ID
                nextId = subtree.treeId + 1;
            }
        }

        //console.log("buildPrimaryStateDict(): tree=");
        //console.log(tree);
        return tree;
    }

    //
    // Find the unique set of service tag IDs that are included in the list
    //
    const getServiceTagOptions = (items) => {
        let tags = [];
        items.forEach( (item) => {
            if(item.children && item.children.length > 0) {
                let childTags = getServiceTagOptions(item.children);
                tags = tags.concat(childTags);
            } else {
                // Make sure this is a talkgroup...
                if(item.hasOwnProperty("serviceId") && item.hasOwnProperty("serviceName")) {
                    let tag = {
                        id: item.serviceId,
                        name: item.serviceName
                    };
                    tags.push(tag);
                }
            }
        });

        //console.log("getServiceTagOptions() tags=");
        //console.log(tags);
        const map = new Map(tags.map(tag => [tag.id, tag]));
        const uniques = [...map.values()];
        //console.log("getServiceTagOptions() uniques=");
        //console.log(uniques);
        return uniques;
    }

    //
    // Derive a dictionary/tree data structure for the Checkbox control from the primary state dictionary
    //
    const buildCheckboxTree = (state_dict, visibility_map, serviceTagsToInclude) => {
        let tree = [];

        //console.log("buildCheckboxTree: state_dict=");
        //console.log(state_dict);
        for (let idx in state_dict) {
            let aggregation = state_dict[idx];

            let group_name = aggregation.name;
            let propagated_visibility = null;
            if(visibility_map && group_name in visibility_map) {
                propagated_visibility = visibility_map[group_name];
                aggregation.visible = propagated_visibility;
            }

            let childTree = filterItemsForCheckboxTree(aggregation.children, visibility_map, propagated_visibility, serviceTagsToInclude);
            if(childTree.length > 0) {
                let child = {...aggregation, children: childTree, stateRef: aggregation};
                tree.push(child);
            }
        }

        return tree;
    }

    const filterItemsForCheckboxTree = (items, visibility_map, visible, serviceTagsToInclude) => {
        let tree = [];
        for (let idx in items) {
            let aggregation = items[idx];
            let group_name = aggregation.name;

            // It is possible to override the current visibilty setting and flow that down to all children.
            // The main example for using the visibility map is group_name==<Sites>
            let propagated_visibility = visible;
            if(visibility_map && group_name in visibility_map) {
                propagated_visibility = visibility_map[group_name];
                aggregation.visible = propagated_visibility;
            } else if (propagated_visibility != null) {
                aggregation.visible = propagated_visibility;
            }

            let r = radiusInMiles(aggregation.maxRadius);
            // Only include this if it's in the right range
            if(r >= minRadius) {
                // Include items based on override state
                let hasOverride = (aggregation.hasOverride);
                let showItem = (showOverride === "show" || (showOverride === "only" && hasOverride) || (showOverride === "hide" && !hasOverride));
                if(showItem) {
                    // Now compare against the filter textbox
                    let filter = filterText.toUpperCase();
                    let search = aggregation.searchText.toUpperCase();
                    if(filter.length === 0 || search.includes(filter)) {
                        // Children will be new objects copied from original.
                        // Add a 'stateRef' property to each with a reference to the original object.
                        // That will allow us to find & update the original state when toggled.
                        if(aggregation.children.length > 0) {
                            // Dictionary.
                            let childTree = filterItemsForCheckboxTree(aggregation.children, visibility_map, propagated_visibility, serviceTagsToInclude);
                            if(childTree.length > 0) {
                                let child = {...aggregation, children: childTree, stateRef: aggregation};
                                tree.push(child);
                            }
                        } else {
                            // Array
                            if(aggregation.hasOwnProperty("serviceId")) {
                                // Include this item if it matches the list of service tags (or that list is null)
                                if(!serviceTagsToInclude || serviceTagsToInclude.some(t => t.id === aggregation.serviceId)) {
                                    let child = {...aggregation, stateRef: aggregation};
                                    tree.push(child);
                                }
                            } else {
                                // This must not be one of the talkgroup leaf nodes.  Include by default.
                                let child = {...aggregation, stateRef: aggregation};
                                tree.push(child);
                            }
                        }
                    }
                }
            }
        }

        //console.log("filterCheckboxTree: tree=");
        //console.log(tree);
        //console.log("--");

        return tree;
    }

    //
    // Derive a data structure for map visualization from the primary state dict
    //
    // This function creates a dictionary with keys for Trunked/Conventional/Agencies.
    // Each of those keys holds another dictionary which is then organized by location.
    // Those location keys each hold an array of objects ('leafs') from the primary state dictionary (described above)
    //
    const buildLocationDict = (state_dict, visibility_map, serviceTagsToInclude) => {
        //console.log("buildLocationDict");

        let dict = {};
        for (let idx in state_dict) {
            // Groups are Trunked/Conventional/Agencies. Each group is rendered in a different color.
            // Each group tracks an independent location lookup map.
            let aggregation = state_dict[idx];
            //console.log("buildLocationDict(): idx="+idx+"; aggregation=");
            //console.log(aggregation);

            let group_name = aggregation.name;
            let propagated_visibility = null;
            if(visibility_map && group_name in visibility_map) {
                propagated_visibility = visibility_map[group_name];
                aggregation.visible = propagated_visibility;
            }

            let leaves = getAllLeafNodes(aggregation, visibility_map, propagated_visibility, serviceTagsToInclude);
            //console.log("leaves=");
            //console.log(leaves);

            let included = filterItemsForLocationDict(leaves);
            //console.log("included=");
            //console.log(included);

            // And store the dictionary for this group
            dict[aggregation.name] = included;
        }

        //console.log("buildLocationDict(): dict=");
        //console.log(dict);
        return dict;
    }

    const filterItemsForLocationDict = (leaves) => {
        let included = {};
        
        for(let idx in leaves) {
            let leaf = leaves[idx];

            let item = leaf.data;
            if(leaf.visible && item) {
                let location = item.location;
                let r = radiusInMiles(location.radius);
                // Only render this if it's in the right size range
                if(r >= minRadius) {
                    // Show items based on override state
                    let hasOverride = (item.locationOverride != null);
                    let showItem = (showOverride === "show" || (showOverride === "only" && hasOverride) || (showOverride === "hide" && !hasOverride));
                    if(showItem) {
                        // Now compare against the filter textbox
                        let filter = filterText.toUpperCase();
                        // IS THIS CORRECT ('leaf')? Or should it be something on 'item'?
                        let search = leaf.searchText.toUpperCase(); 
                        if(filter.length === 0 || search.includes(filter)) {
                            // The dictionary will handle lookups by location and hold all matching items
                            let key = dictKeyFromLocation(item.location)
                            if( !(key in included)) {
                                included[key] = [];
                            }
                            // Now append this to the set of items with matching location parameters
                            included[key].push(leaf);
                        }
                    }
                }
            }
        }

        return included;
    }
    
    const getAllLeafNodes = (group, visibility_map, visible, serviceTagsToInclude) => {
        //console.log("getAllLeafNodes");
        //console.log(group);

        let children = group.children;
        if(children.length > 0 && !group.data) {
            // Dictionary.
            let leaves = [];
            Object.keys(children).forEach( (key) => {
                let child = children[key];
                let group_name = child.name;

                // It is possible to override the current visibilty setting and flow that down to all children.
                // The main example for using the visibility map is group_name==<Sites>
                let propagated_visibility = visible;
                if(visibility_map && group_name in visibility_map) {
                    propagated_visibility = visibility_map[group_name];
                    child.visible = propagated_visibility;
                } else if (propagated_visibility != null) {
                    child.visible = propagated_visibility;
                }

                let child_leaves = getAllLeafNodes(child, visibility_map, propagated_visibility, serviceTagsToInclude);
                if(child_leaves) {
                    leaves.push(...child_leaves);
                }
            })
            return leaves;
        } else { // Array
            // Create a copy of the object, but maintain a reference to the original object from the state dictionary.
            // That will allow us to find & update the original state when hidden/shown.
            let leaf = {...group, stateRef: group};

            // If this is a department, we also need to filter the talkgroups.
            if(group.nodeType === NODE_TYPE_DEPT) {
                let tgs = pruneTalkgroups(group.children, serviceTagsToInclude);

                // If the filter results in an empty list, then this whole deparment should be excluded to.
                // So only keep it if there are remaining talkgroups
                if(tgs.length > 0) {
                    leaf.children = tgs;
                    return [leaf];
                } else {
                    return null;
                }
            } else {
                return [leaf];
            }
        }
    }

    const pruneTalkgroups = (talkgroups, tagsToMatch) => {
        let keepers = [];
        for(let idx in talkgroups) {
            let tg = talkgroups[idx];
            // Include this item if it matches the list of service tags (or that list is null)
            if(!tagsToMatch || tagsToMatch.some(t => t.id === tg.serviceId)) {
                keepers.push(tg);
            }
        }
        return keepers;
    }

    return (
        <div style={{ width: '100%', height: "100%"}}>
            <div className={classes.displayArea} style={{ float: 'left', width: '75%', position: "relative" }}>
                <SystemsMapDisplayOptions 
                    isDisabled={locations == null} 
                    onSliderChange={sliderOnChange} 
                    onOverrideSelectionChange={setShowOverride} overrideValue={showOverride}
                    onSelectTrunkedSys={setShowTrunkedSys} trunkedSysSelection={showTrunkedSys}
                    onSelectTrunkedSites={setShowTrunkedSites} trunkedSitesSelection={showTrunkedSites}
                    onSelectConventional={setShowConventional} conventionalSelection={showConventional}
                    serviceChoices={serviceChoices} serviceSelections={serviceSelections}
                    setServiceSelections={handleSetServiceSelectionValues}
                />
                <FrequencyDetailsDrawer frequencies={frequencies} />
                <SystemsMapDisplay
                    itemLocationDict={itemLocationDict} 
                    infoWindowToShow={selectedLocation}
                    hideShowSingleMarker={hideShowSingleMarker}
                    hideShowAllMarkers={hideShowAllMarkers}
                    addLocationOverride={addLocationOverride} 
                    updateLocationOverride={updateLocationOverride}
                    deleteLocationOverride={deleteLocationOverride}
                />
            </div>
            <div className={classes.rightColumn} style={{ float: 'right', width: '25%' }} >
                <div className={classes.fixedSize} style={{ overflowX: "auto"}} >
                    <Container>
                        <TextField
                            variant="standard"
                            disabled={locations == null}
                            label="Filter"
                            onKeyPress={(e) => {
                                if(e.key  === 'Enter'){
                                    setFilterText(e.target.value);
                                }
                            }} /> <br/>
                    </Container>
                </div>
                <div className={classes.expandToRemainder} >
                    <SystemsTreeView items={checkboxTree} checked={true} 
                                    onToggle={toggleCheckboxItem}
                                    onSelect={handleTreeSelection} />
                </div>
            </div>
        </div>
    );
}

export default SystemsDetails;
