import React from 'react';
import { useState, useEffect } from 'react';

import { ProjectSummaryView } from '../ProjectSummaryView/ProjectSummaryView';

import { List, ListItemButton } from '@mui/material';

import { getSortedArrayByDictKey } from '../../lib/helperUtils';

const ProjectListSelectableView = ({projects, artifacts, summaries, 
                                    refreshDate, keywordLists,
                                    handleDelete, handleEdit, handleClone, handleRefresh,
                                    handleSelectionChange}) => {
    //const classes = useStyles();

    const [selectedIndex, setSelectedIndex] = useState(-1);
    const [sortedItems, setSortedItems] = useState([]);

    // Sort the items for display whenever the projects change
    useEffect(() => {
        let sorted = getSortedArrayByDictKey(projects, "projectName");
        setSortedItems(sorted);
    }, [projects]);
  
    const handleListItemClick = (event, index) => {
        setSelectedIndex(index);
        // Now notify the parent that we have a new selection
        if(index >= 0) {
            var project = sortedItems[index];
            var project_id = project.projectId;
            if(handleSelectionChange) {
                handleSelectionChange(project_id);
            }
        }
    };

    return (
        <div  style={{ "overflowX": "auto", "overflowY": "auto"}}>
            <List component="nav" dense={true} disablePadding={true} >
            {
                sortedItems.map((project, index) => {
                    const statusCode = (project.status != null) ? (project.status.code) : 0;
                    const isError = (statusCode === -1);

                    let summary = (summaries && project.projectId in summaries) ? summaries[project.projectId] : null;
                    let artifact = (artifacts && project.projectId in artifacts) ? artifacts[project.projectId] : null;

                    return (
                        <ListItemButton selected={selectedIndex === index}
                                        onClick={(event) => handleListItemClick(event, index)}
                                        key={"ProjectListSelectableView_"+project.projectId}
                                        disabled={!isError && (!summary || !artifact)}
                        >
                            <ProjectSummaryView project={project} project_id={project.projectId}
                                                summary={summary}
                                                artifacts={artifact} 
                                                refreshDate={refreshDate} keywordLists={keywordLists}
                                                handleDelete={handleDelete} handleEdit={handleEdit} 
                                                handleClone={handleClone} handleRefresh={handleRefresh} />
                        </ListItemButton>
                    );
                })
            }
            </List>
        </div>
    );
}

export default ProjectListSelectableView;
