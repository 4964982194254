import React from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';
import CssBaseline from '@mui/material/CssBaseline';

import { BrowserRouter } from 'react-router-dom';
import App from './App';

import { Provider } from 'react-redux'
import store from './store'

import { setSession } from './actions/session'

import cognitoUtils from './lib/cognitoUtils'

const session = cognitoUtils.loadExistingSession();
if(session) {
  store.dispatch(setSession(session));
}

ReactDOM.render(
  <React.StrictMode>
    <CssBaseline/>
    <BrowserRouter>
      <Provider store={store}>
        <App />
      </Provider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
