import React from 'react'

import { Menu, IconButton, MenuItem, Link } from '@mui/material';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';

export const ArtifactItemList = ({project_id, files}) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
  
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleClose = () => {
      setAnchorEl(null);
    };

    const fileSizeString = (size) => {
        let KB = 1024;
        let MB = KB*KB;

        let s = "";
        if(size > MB) {
            let truncated = (size/MB);
            s = truncated.toFixed(1)+" MB";
        } else if(size > KB) {
            let truncated = (size/KB);
            s = truncated.toFixed(1)+" KB";
        } else {
            s = size+" bytes";
        }
        return s;
    }


    if(files && files.length > 0) {
        return (
            <div>
                <IconButton
                    key={project_id+"-download"}
                    color="primary"
                    aria-label="download HPE file"
                    component="span"
                    onClick={(event) => {
                        handleClick(event);
                        event.stopPropagation();
                    }}
                    onFocus={(event) => event.stopPropagation()}
                    size="large">
                    <FolderOpenIcon fontSize="small" color="primary"/>
                </IconButton>
                <Menu id="simple-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={(event) => {
                            handleClose(event);
                            event.stopPropagation();
                        }}                    
                        elevation={0}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                >
                    {files.map((file) => (
                        <MenuItem key={project_id+"-"+file.file_name}>
                            <Link
                                href={file.url}
                                onClick={(event) => event.stopPropagation()}
                                underline="hover">{file.file_name} ({fileSizeString(file.file_size)})</Link>
                        </MenuItem>
                    ))}
                </Menu>
            </div>
        );
    } else {
        return (
            <IconButton
                key={project_id+"-download"}
                disabled={true}
                color="primary"
                aria-label="download HPE file"
                component="span"
                onClick={(event) => event.stopPropagation()}
                onFocus={(event) => event.stopPropagation()}
                size="large">
                <FolderOpenIcon fontSize="small"/>
            </IconButton>
        );
    }
}

export default ArtifactItemList;
