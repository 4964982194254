import React from 'react';

import { ToggleButtonGroup, ToggleButton } from '@mui/material';

export const ThreeWaySelection = ({name, kv1, kv2, kv3, value, onChange}) => {
    return (
        <ToggleButtonGroup exclusive size="small" 
                            value={value} 
                            onChange={(e,v) => {
                                e.target.name = name;
                                e.target.value = v;
                                // Don't allow second click to toggle the switch off
                                if(v != null) {
                                    onChange(e,v);
                                }
                            }}>
            <ToggleButton value={kv1[1]}>{kv1[0]}</ToggleButton>
            <ToggleButton value={kv2[1]}>{kv2[0]}</ToggleButton>
            <ToggleButton value={kv3[1]}>{kv3[0]}</ToggleButton>
        </ToggleButtonGroup>
    )
}

export default ThreeWaySelection;
